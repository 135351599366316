import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_STYLES,
  BEGIN_EDIT_STYLES,
  BEGIN_STYLES_DOWNLOAD,
  RETRIEVE_STYLES_BULK_DELETE,
  RETRIEVE_STYLES_DELETE,
} from "../../constants/modules/stylesConstants";

import {
  addStyleErrorAction,
  addStyleOkAction,
  bulkDeleteStyleErrorAction,
  bulkDeleteStyleOkAction,
  deleteStyleErrorAction,
  deleteStyleOkAction,
  downloadStylesErrorAction,
  downloadStylesOkAction,
  editStyleErrorAction,
  editStyleOkAction,
} from "../../actions/modules/stylesActions";

import {
  addStyleDB,
  bulkDeleteStyleDB,
  deleteStyleDB,
  editStyleDB,
  retrieveStylesData,
} from "../../../config/apiCalls/stylesApi";

// Retrieve products
// worker saga
export function* retrieveStyles() {
  try {
    const response = yield call(retrieveStylesData);

    console.log("ttttttttttttt", response);

    if (response.status === 200) {
      yield put(downloadStylesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadStylesErrorAction());
  }
}

// Create new product
// worker saga
export function* addStyle(action) {
  const style = action.style;

  try {
    const response = yield call(addStyleDB, style);

    if (response.status === 201) {
      yield delay(500);

      yield put(addStyleOkAction(style));
      toast.success("Style created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addStyleErrorAction(true));
    toast.error("Style created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteStyle(action) {
  const id = action.payload;
  try {
    yield call(deleteStyleDB, id);
    yield deleteStyleOkAction();
    toast.success("Style deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteStyleErrorAction();
  }
}

// Edit product
// worker saga
export function* editStyle(action) {
  const style = action.style;

  try {
    yield call(editStyleDB, style);
    yield editStyleOkAction(style);
    // Alert
    toast.success("Style has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editStyleErrorAction();
  }
}

// BULK SAGAS
export function* bulkStyleDelete(action) {
  const style = action.style;

  try {
    const response = yield call(bulkDeleteStyleDB, style);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteStyleOkAction(style));
      toast.success("Bulk Style deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteStyleErrorAction(true));
    toast.error("Bulk Style deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchStylesAsync() {
  yield takeLatest(BEGIN_STYLES_DOWNLOAD, retrieveStyles);
  yield takeLatest(ADD_STYLES, addStyle);
  yield takeLatest(RETRIEVE_STYLES_DELETE, deleteStyle);
  yield takeLatest(BEGIN_EDIT_STYLES, editStyle);
  yield takeLatest(RETRIEVE_STYLES_BULK_DELETE, bulkStyleDelete);
}
