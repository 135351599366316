import axiosClient from "../axiosClient";

export async function bulkDeleteStyleDB(ids) {
  return await axiosClient.post(`/styles/bulk_delete`, ids);
}

export async function retrieveStylesData() {
  return await axiosClient.get("/styles");
}

export async function addStyleDB(value) {
  var bodyFormData = new FormData();

  bodyFormData.append("buyer_id", value.buyer_id);
  bodyFormData.append("style_name", value.style_name);
  bodyFormData.append("item_name", value.item_name);
  bodyFormData.append("description", value.description);
  bodyFormData.append("style_image", value.style_image);
  bodyFormData.append("active_status", value.active_status);

  return await axiosClient.post("/styles", bodyFormData);
}

export async function deleteStyleDB(id) {
  return await axiosClient.delete(`/styles/${id}`);
}

export async function editStyleDB(value) {
  var bodyFormData = new FormData();

  bodyFormData.append("buyer_id", value.buyer_id);
  bodyFormData.append("style_name", value.style_name);
  bodyFormData.append("item_name", value.item_name);
  bodyFormData.append("description", value.description);
  bodyFormData.append("style_image", value.style_image);
  bodyFormData.append("active_status", value.active_status);

  return await axiosClient.put(`/styles/${value.id}`, bodyFormData);
}
