import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_TNA_TEMPLATE_ACTIONS,
  BEGIN_EDIT_TNA_TEMPLATE_ACTIONS,
  BEGIN_TNA_TEMPLATE_ACTIONS_DOWNLOAD,
  RETRIEVE_TNA_TEMPLATE_ACTIONS_BULK_DELETE,
  RETRIEVE_TNA_TEMPLATE_ACTIONS_DELETE,
} from "../../constants/modules/tnaTemplatesActionsConstants";

import {
  addTnaTemplateActionErrorAction,
  addTnaTemplateActionOkAction,
  bulkDeleteTnaTemplateActionErrorAction,
  bulkDeleteTnaTemplateActionOkAction,
  deleteTnaTemplateActionErrorAction,
  deleteTnaTemplateActionOkAction,
  downloadTnaTemplateActionsErrorAction,
  downloadTnaTemplateActionsOkAction,
  editTnaTemplateActionErrorAction,
  editTnaTemplateActionOkAction,
} from "../../actions/modules/tnaTemplateActionActions";

import {
  addTnaTemplateActionDB,
  bulkDeleteTnaTemplateActionDB,
  deleteTnaTemplateActionDB,
  editTnaTemplateActionDB,
  retrieveTnaTemplateActionsData,
} from "../../../config/apiCalls/tnaTemplateActions";

// Retrieve products
// worker saga
export function* retrieveTnaTemplateActions() {
  try {
    const response = yield call(retrieveTnaTemplateActionsData);

    if (response.status === 200) {
      yield put(downloadTnaTemplateActionsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTnaTemplateActionsErrorAction());
  }
}

// Create new product
// worker saga
export function* addTnaTemplateAction(action) {
  const tnaTemplateAction = action.tnaTemplateAction;

  try {
    const response = yield call(addTnaTemplateActionDB, tnaTemplateAction);

    if (response.status === 200) {
      yield delay(500);

      yield put(addTnaTemplateActionOkAction(tnaTemplateAction));
      toast.success("Tna Template Updated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addTnaTemplateActionErrorAction(true));
    toast.error("Tna Template Update Failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteTnaTemplateAction(action) {
  const id = action.payload;
  try {
    yield call(deleteTnaTemplateActionDB, id);
    yield deleteTnaTemplateActionOkAction();
    toast.success("TnaTemplateAction deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteTnaTemplateActionErrorAction();
  }
}

// Edit product
// worker saga
export function* editTnaTemplateAction(action) {
  const tnaTemplateAction = action.tnaTemplateAction;

  try {
    yield call(editTnaTemplateActionDB, tnaTemplateAction);
    yield editTnaTemplateActionOkAction(tnaTemplateAction);
    // Alert
    // Alert
    toast.success("Tna-Action has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editTnaTemplateActionErrorAction();
  }
}

// BULK SAGAS
export function* bulkTnaTemplateActionDelete(action) {
  const tnaTemplateAction = action.tnaTemplateAction;

  try {
    const response = yield call(
      bulkDeleteTnaTemplateActionDB,
      tnaTemplateAction
    );
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteTnaTemplateActionOkAction(tnaTemplateAction));
      toast.success("Tna Actions deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteTnaTemplateActionErrorAction(true));
    toast.error("Tna Actions deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchTnaTemplateActionsAsync() {
  yield takeLatest(
    BEGIN_TNA_TEMPLATE_ACTIONS_DOWNLOAD,
    retrieveTnaTemplateActions
  );
  yield takeLatest(ADD_TNA_TEMPLATE_ACTIONS, addTnaTemplateAction);
  yield takeLatest(
    RETRIEVE_TNA_TEMPLATE_ACTIONS_DELETE,
    deleteTnaTemplateAction
  );
  yield takeLatest(BEGIN_EDIT_TNA_TEMPLATE_ACTIONS, editTnaTemplateAction);
  yield takeLatest(
    RETRIEVE_TNA_TEMPLATE_ACTIONS_BULK_DELETE,
    bulkTnaTemplateActionDelete
  );
}
