export const ADD_COSTING_ITEM_GROUPS = "ADD_COSTING_ITEM_GROUPS";
export const ADD_COSTING_ITEM_GROUPS_OK = "ADD_COSTING_ITEM_GROUPS_OK";
export const ADD_COSTING_ITEM_GROUPS_ERROR = "ADD_COSTING_ITEM_GROUPS_ERROR";

export const BEGIN_COSTING_ITEM_GROUPS_DOWNLOAD =
  "BEGIN_COSTING_ITEM_GROUPS_DOWNLOAD";
export const COSTING_ITEM_GROUPS_DOWNLOAD_OK =
  "COSTING_ITEM_GROUPS_DOWNLOAD_OK";
export const COSTING_ITEM_GROUPS_DOWNLOAD_ERROR =
  "COSTING_ITEM_GROUPS_DOWNLOAD_ERROR";

export const RETRIEVE_COSTING_ITEM_GROUPS_DELETE =
  "RETRIEVE_COSTING_ITEM_GROUPS_DELETE";
export const COSTING_ITEM_GROUPS_DELETED_OK = "COSTING_ITEM_GROUPS_DELETED_OK";
export const COSTING_ITEM_GROUPS_DELETED_ERROR =
  "COSTING_ITEM_GROUPS_DELETED_ERROR";

export const RETRIEVE_COSTING_ITEM_GROUPS_EDIT =
  "RETRIEVE_COSTING_ITEM_GROUPS_EDITED";
export const BEGIN_EDIT_COSTING_ITEM_GROUPS = "BEGIN_EDIT_COSTING_ITEM_GROUPS";
export const COSTING_ITEM_GROUPS_EDITED_OK = "COSTING_ITEM_GROUPS_EDITED_OK";
export const COSTING_ITEM_GROUPS_EDITED_ERROR =
  "COSTING_ITEM_GROUPS_EDITED_ERROR";

export const RETRIEVE_COSTING_ITEM_GROUPS_BULK_DELETE =
  "RETRIEVE_COSTING_ITEM_GROUPS_BULK_DELETE";
export const COSTING_ITEM_GROUPS_BULK_DELETED_OK =
  "COSTING_ITEM_GROUPS_BULK_DELETED_OK";
export const COSTING_ITEM_GROUPS_BULK_DELETED_ERROR =
  "COSTING_ITEM_GROUPS_BULK_DELETED_ERROR";
