import {
  ADD_STYLES,
  ADD_STYLES_ERROR,
  ADD_STYLES_OK,
  BEGIN_EDIT_STYLES,
  BEGIN_STYLES_DOWNLOAD,
  RETRIEVE_STYLES_BULK_DELETE,
  RETRIEVE_STYLES_DELETE,
  RETRIEVE_STYLES_EDIT,
  STYLES_BULK_DELETED_ERROR,
  STYLES_BULK_DELETED_OK,
  STYLES_DELETED_ERROR,
  STYLES_DELETED_OK,
  STYLES_DOWNLOAD_ERROR,
  STYLES_DOWNLOAD_OK,
  STYLES_EDITED_ERROR,
  STYLES_EDITED_OK,
} from "../../constants/modules/stylesConstants";

const initialState = {
  styles: [],
  error: false,
  loading: false,
  deleteStyle: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_STYLES_DOWNLOAD:
    case RETRIEVE_STYLES_BULK_DELETE:
    case ADD_STYLES:
    case BEGIN_EDIT_STYLES:
      return {
        ...state,
        loading: action.payload,
        style: action.style,
      };

    case ADD_STYLES_OK:
    case STYLES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_STYLES_ERROR:
    case STYLES_DOWNLOAD_ERROR:
    case STYLES_BULK_DELETED_ERROR:
    case STYLES_DELETED_ERROR:
    case STYLES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case STYLES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        styles: action.payload,
      };

    case RETRIEVE_STYLES_DELETE:
      return {
        ...state,
        deleteStyle: action.payload,
      };

    case STYLES_DELETED_OK:
      return {
        ...state,
        styles: state.styles.data.filter(
          (style) => style.id !== state.deleteStyle
        ),
        deleteStyle: null,
      };

    case RETRIEVE_STYLES_EDIT:
      return {
        ...state,
        editStyle: action.payload,
      };

    case STYLES_EDITED_OK:
      return {
        ...state,
        editStyle: null,
        styles: state.styles.data.map((style) =>
          style.id === action.payload.id ? (style = action.payload) : style
        ),
      };

    default:
      return state;
  }
}
