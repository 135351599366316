import axiosClient from "../axiosClient";

export async function bulkDeleteCostingItemGroupDB(ids) {
  return await axiosClient.post(`/costing_item_groups/bulk_delete`, ids);
}

export async function retrieveCostingItemGroupsData() {
  return await axiosClient.get("/costing_item_groups");
}

export async function addCostingItemGroupDB(value) {
  return await axiosClient.post("/costing_item_groups", value);
}

export async function deleteCostingItemGroupDB(id) {
  return await axiosClient.delete(`/costing_item_groups/${id}`);
}

export async function editCostingItemGroupDB(value) {
  return await axiosClient.put(`/costing_item_groups/${value.id}`, value);
}
