import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";

// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const tableBodyData = invoiceData ? invoiceData : [];

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : [];

  // quantity
  const totalOrderQty = tableBodyData?.reduce(
    (preValue, currentValue) => preValue + Number(currentValue?.order_qty),
    0
  );

  const totalShippedQty = tableBodyData?.reduce(
    (preValue, currentValue) => preValue + Number(currentValue?.ship_qty),
    0
  );

  const totalBalanceQty = tableBodyData?.reduce(
    (preValue, currentValue) => preValue + Number(currentValue?.balance_qty),
    0
  );
  // value
  const totalExportValue = tableBodyData?.reduce(
    (preValue, currentValue) => preValue + Number(currentValue?.export_value),
    0
  );
  const totalBalShipmentValue = tableBodyData?.reduce(
    (preValue, currentValue) => preValue + Number(currentValue?.bal_qty),
    0
  );
  const totalProceedValue = tableBodyData?.reduce(
    (preValue, currentValue) => preValue + Number(currentValue?.procced_val),
    0
  );
  const totalFDDValue = tableBodyData?.reduce(
    (preValue, currentValue) => preValue + Number(currentValue?.fdd_amt),
    0
  );
  const totalCommissionValue = tableBodyData?.reduce(
    (preValue, currentValue) => preValue + Number(currentValue?.comm_value),
    0
  );

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Image
            style={{
              width: "700px",
              height: "75px",
              textAlign: "center",
              margin: "auto",
            }}
            src={logo}
          />
          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                backgroundColor: "rgb(56, 56, 141)",
                width: "250px",
                color: "white",
                padding: "6px",
              }}
            >
              Master L/C Export Summary
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-60px",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: "9px", fontWeight: 900 }}>
              Print Date : {defaultValue}
            </Text>
            <Text style={{ fontSize: "9px", fontWeight: 900 }}>
              Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
            </Text>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              // width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "0 3px",
                width: "50px",
                fontSize: "11px",
                fontWeight: "bold",
                paddingTop: 12,
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              SL
            </Text>

            <Text
              style={{
                padding: "0 3px",
                width: "150px",
                fontSize: "11px",
                fontWeight: "bold",
                paddingTop: 12,
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Buyer Name
            </Text>
            <Text
              style={{
                padding: "0 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 12,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Master L/C No.
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "170px",
                paddingTop: 8,
                fontWeight: "bold",
                borderRight: "1px solid black",
                fontSize: "11px",
                textAlign: "center",
              }}
            >
              Total Master LC Value
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 8,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Latest Shipment Date
            </Text>
            <Text
              style={{
                padding: "0 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 12,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Expiry Date
            </Text>
            <Text
              style={{
                padding: "0 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 12,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Total Order Qty
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 8,
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Total Shipped Qty
            </Text>
            <Text
              style={{
                padding: "0 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 12,
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Balance Qty
            </Text>
            <Text
              style={{
                padding: "0 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 12,
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Export Value
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 8,
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Balance Shipment Value
            </Text>
            <Text
              style={{
                padding: "0 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 12,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Proceed Value
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 8,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Factory FDD Value
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 8,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Commission Value
            </Text>
            <Text
              style={{
                padding: "0 3px",
                fontSize: "11px",
                width: "170px",
                paddingTop: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              L/C Status
            </Text>
          </View>
          <View>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length &&
              tableBodyDataRefine.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  style={{
                    flexDirection: "row",
                    // width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "50px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "150px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.buyer_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.lc_no}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.lc_value).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.latest_shipment_date}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.lc_expiry_date}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.order_qty}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.ship_qty}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.bal_qty).toFixed(2)}
                  </Text>

                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.export_value).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.balance_qty).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.procced_val).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.fdd_amt).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.comm_value).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "170px",
                      textAlign: "center",
                    }}
                  >
                    {item?.lc_status === "o" ? "Open" : "Closed"}
                  </Text>
                </View>
              ))}
            <View
              style={{
                flexDirection: "row",
                // width: "100%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "14px",
                  height: "auto",
                  width: "200px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalOrderQty}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalShippedQty}
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {Number(totalBalShipmentValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {Number(totalExportValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalBalanceQty}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {Number(totalProceedValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {Number(totalFDDValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {Number(totalCommissionValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "170px",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",

  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    // fontFamily: "Arial",
    minWidth: "160vw",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
