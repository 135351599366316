export const ADD_STYLES = "ADD_STYLES";
export const ADD_STYLES_OK = "ADD_STYLES_OK";
export const ADD_STYLES_ERROR = "ADD_STYLES_ERROR";

export const BEGIN_STYLES_DOWNLOAD = "BEGIN_STYLES_DOWNLOAD";
export const STYLES_DOWNLOAD_OK = "STYLES_DOWNLOAD_OK";
export const STYLES_DOWNLOAD_ERROR = "STYLES_DOWNLOAD_ERROR";

export const RETRIEVE_STYLES_DELETE = "RETRIEVE_STYLES_DELETE";
export const STYLES_DELETED_OK = "STYLES_DELETED_OK";
export const STYLES_DELETED_ERROR = "STYLES_DELETED_ERROR";

export const RETRIEVE_STYLES_EDIT = "RETRIEVE_STYLES_EDITED";
export const BEGIN_EDIT_STYLES = "BEGIN_EDIT_STYLES";
export const STYLES_EDITED_OK = "STYLES_EDITED_OK";
export const STYLES_EDITED_ERROR = "STYLES_EDITED_ERROR";

export const RETRIEVE_STYLES_BULK_DELETE = "RETRIEVE_STYLES_BULK_DELETE";
export const STYLES_BULK_DELETED_OK = "STYLES_BULK_DELETED_OK";
export const STYLES_BULK_DELETED_ERROR = "STYLES_BULK_DELETED_ERROR";
