import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

const Quixote = ({ invoiceData, state, month }) => {
  const tableBodyHeader = invoiceData && invoiceData.length && invoiceData[0];

  const tableBodyData = invoiceData && invoiceData.length && invoiceData;

  const parameter = tableBodyHeader && tableBodyHeader?.data;

  // parameters
  let buyerParameter = 0;
  if (tableBodyHeader?.buyer_name) {
    buyerParameter++;
  } else {
    if (buyerParameter > 0) {
      buyerParameter--;
    }
  }

  let deprtParameter = 0;
  if (tableBodyHeader?.department) {
    deprtParameter++;
  } else {
    if (deprtParameter > 0) {
      deprtParameter--;
    }
  }

  let teamParameter = 0;
  if (tableBodyHeader?.team_name) {
    teamParameter++;
  } else {
    if (teamParameter > 0) {
      teamParameter--;
    }
  }
  let factoryParameter = 0;
  if (tableBodyHeader?.factory_name) {
    factoryParameter++;
  } else {
    if (factoryParameter > 0) {
      factoryParameter--;
    }
  }

  let porductParameter = 0;
  if (tableBodyHeader?.product_type) {
    porductParameter++;
  } else {
    if (porductParameter > 0) {
      porductParameter--;
    }
  }

  // parameter Summation
  const totalParameterSum =
    buyerParameter +
    deprtParameter +
    teamParameter +
    factoryParameter +
    porductParameter;

  const countParameter = totalParameterSum ? totalParameterSum : 1;

  // Factory Total Calculations
  let exportQtyArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      let item = item2?.buyer_name ? item2.data[0]?.["export_qty"] : "";
      exportQtyArr.push(item);
    });

  let commissionVArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      let item = item2?.buyer_name ? item2.data[0]?.["comm_value"] : "";
      commissionVArr.push(item);
    });

  let rdlValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      let item = item2?.buyer_name ? item2.data[0]?.["rdl_value"] : "";
      rdlValueArr.push(item);
    });

  let factoryValueArr = [];
  tableBodyData &&
    tableBodyData?.map((item2) => {
      let item = item2?.buyer_name ? item2.data[0]?.["factory_value"] : "";
      factoryValueArr.push(item);
    });

    let sl_no = 0;

  return (
    <Document>
      <Page
        orientation="landscape"
        size="A4"
        style={styles.body}
        wrap={true}
      >
        {/* Invoice Image & Header PART */}

        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View style={{ width: "100%", textAlign: "center" }}>
            <Text style={{ fontSize: 16, marginBottom: 5, fontWeight: "bold" }}>
              ETD REPORT
            </Text>
            <Text style={{ fontSize: 9 }}>Renaissance Designs Ltd</Text>
            <Text style={{ fontSize: 9, paddingTop: 4, paddingBottom: 4 }}>
              House 14,Road 2,Sector 3,Uttara,Dhaka,Bangladesh
            </Text>
          </View>
        </View>

        {/* Header Info */}
        <View
          style={{
            marginTop: "30px",
            paddingLeft: 20,
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "550x",
              textAlign: "center",
              border: "1px solid black",
            }}
          >
            <View
              style={{ flexDirection: "row", borderBottom: "1px solid black" }}
            >
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "120px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  borderBottom: 0,
                }}
              >
                Report Type
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "330px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: 0,
                }}
              >
                Sales Based on ETD
              </Text>
            </View>
            <View
              style={{ flexDirection: "row", borderBottom: "1px solid black" }}
            >
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "120px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  borderBottom: 0,
                }}
              >
                Sales Year
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "330px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: 0,
                }}
              >
                {tableBodyData && tableBodyData.length
                  ? tableBodyData[0].year
                  : null}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "120px",
                  fontWeight: "bold",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  borderBottom: 0,
                }}
              >
                Sales Month{" "}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  padding: 5,
                  width: "330px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: 0,
                }}
              >
                {month}
              </Text>
            </View>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "30px" }}>
          <View fixed>
            {/* First Header */}
            <View
              style={{
                border: "1px solid black",
                borderBottom: 0,
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  width: `${10 * countParameter + 2.1}%`,
                  textAlign: "center",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                Month
              </Text>
              {parameter && Number(parameter?.[0]?.month) === 1 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  JAN
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 2 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  FEB
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 3 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  MAR
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 4 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  APR
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 5 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  MAY
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 6 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  JUN
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 7 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  JUL
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 8 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  AUG
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 9 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  SEP
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 10 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  OCT
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 11 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  NOV
                </Text>
              ) : null}
              {parameter && Number(parameter?.[0]?.month) === 12 ? (
                <Text
                  style={{
                    padding: 5,
                    width: `${100 - 10 * countParameter}%`,
                    fontSize: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  DEC
                </Text>
              ) : null}
            </View>
            {/* Second Header */}
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  textAlign: "center",
                  alignItems: "center",
                  width: "2%",
                  paddingTop: 7,
                }}
              >
                SL
              </Text>

              {tableBodyHeader && tableBodyHeader?.buyer_name ? (
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    width: "10%",
                    borderLeft: "1px solid black",
                    textAlign: "center",
                    paddingTop: 7,
                  }}
                >
                  Buyer
                </Text>
              ) : null}
              {tableBodyHeader && tableBodyHeader?.department ? (
                <Text
                  style={{
                    paddingTop: 7,
                    width: "10%",
                    fontSize: 10,
                    borderLeft: "1px solid black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Dept
                </Text>
              ) : null}
              {tableBodyHeader && tableBodyHeader?.team_name ? (
                <Text
                  style={{
                    paddingTop: 7,
                    width: "10%",
                    fontSize: 10,
                    borderLeft: "1px solid black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Team
                </Text>
              ) : null}
              {tableBodyHeader && tableBodyHeader?.factory_name ? (
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    width: "10%",
                    borderLeft: "1px solid black",
                    textAlign: "center",
                    paddingTop: 7,
                  }}
                >
                  Factory
                </Text>
              ) : null}
              {tableBodyHeader && tableBodyHeader?.product_type ? (
                <Text
                  style={{
                    paddingTop: 7,
                    width: "10%",
                    fontSize: 9,
                    fontWeight: "bold",
                    borderLeft: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Product Type
                </Text>
              ) : null}
              {parameter && parameter?.[0]?.export_qty ? (
                <Text
                  style={{
                    paddingTop: 7,
                    height: 25,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity
                </Text>
              ) : null}
              {parameter && parameter?.[0]?.rdl_value ? (
                <Text
                  style={{
                    paddingTop: 7,
                    height: 25,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  RDL Value
                </Text>
              ) : null}
              {parameter && parameter?.[0]?.factory_value ? (
                <Text
                  style={{
                    paddingTop: 7,
                    height: 25,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Factory Value
                </Text>
              ) : null}
              {parameter && parameter?.[0]?.comm_value ? (
                <Text
                  style={{
                    paddingTop: 7,
                    height: 25,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Commission Value (USD)
                </Text>
              ) : null}
              {parameter && parameter?.[0]?.comm_per ? (
                <Text
                  style={{
                    paddingTop: 7,
                    height: 25,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 9,
                    borderLeft: "1px solid black",
                  }}
                >
                  Quantity(%) of Total
                </Text>
              ) : null}
              
            </View>
          </View>

          {/* Table Body Part Start */}
          <View>
            {tableBodyData &&
              tableBodyData.length &&
              tableBodyData.map((item, index) => {
                if(item.buyer_name){
                  sl_no++;
                }
                return item?.buyer_name ? (<View
                  style={{
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                  key={index}
                  wrap={false}
                  break={index > 11}
                >
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      {/* parameter */}
                      <View
                        style={{
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <Text
                          style={{
                            padding: 5,
                            fontSize: 9,
                            width: "2%",
                            textAlign: "center",
                          }}
                        >
                          {sl_no}
                        </Text>
                        {tableBodyHeader?.buyer_name ? (
                          <Text
                            style={{
                              padding: 5,
                              fontSize: 9,
                              width: "10%",
                              textAlign: "center",
                              borderLeft: "1px solid black",
                            }}
                          >
                            {item?.buyer_name}
                          </Text>
                        ) : null}

                        {tableBodyHeader?.department ? (
                          <Text
                            style={{
                              padding: 5,
                              width: "10%",
                              fontSize: 9,
                              borderLeft: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {item?.department}
                          </Text>
                        ) : null}
                        {tableBodyHeader?.team_name ? (
                          <Text
                            style={{
                              padding: 5,
                              width: "10%",
                              fontSize: 9,
                              borderLeft: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {item?.team_name}
                          </Text>
                        ) : null}
                        {tableBodyHeader?.factory_name ? (
                          <Text
                            style={{
                              padding: 5,
                              fontSize: 9,
                              width: "10%",
                              textAlign: "center",
                              borderLeft: "1px solid black",
                            }}
                          >
                            {item?.factory_name}
                          </Text>
                        ) : null}
                        {tableBodyHeader?.product_type ? (
                          <Text
                            style={{
                              padding: 5,
                              fontSize: 9,
                              width: "10%",
                              textAlign: "center",
                              borderLeft: "1px solid black",
                            }}
                          >
                            {item?.product_type}
                          </Text>
                        ) : null}
                        {/* result */}
                        {parameter?.[0]?.export_qty ? (
                          <Text
                            style={{
                              padding: 5,
                              textAlign: "center",
                              width: `${(100 - 10 * countParameter) / 5}%`,
                              fontSize: 9,
                              borderLeft: "1px solid black",
                            }}
                          >
                            {item?.data?.[0]?.export_qty}
                          </Text>
                        ) : null}
                        {parameter?.[0]?.rdl_value ? (
                          <Text
                            style={{
                              padding: 5,
                              textAlign: "center",
                              width: `${(100 - 10 * countParameter) / 5}%`,
                              fontSize: 9,
                              borderLeft: "1px solid black",
                            }}
                          >
                           $ {item?.data?.[0]?.rdl_value ? Number(item?.data?.[0]?.rdl_value).toFixed(2) : "0.00"}
                          </Text>
                        ) : null}
                        {parameter?.[0]?.factory_value ? (
                          <Text
                            style={{
                              padding: 5,
                              textAlign: "center",
                              width: `${(100 - 10 * countParameter) / 5}%`,
                              fontSize: 9,
                              borderLeft: "1px solid black",
                            }}
                          >
                           $ {item?.data?.[0]?.factory_value ? Number(item?.data?.[0]?.factory_value).toFixed(2) : "0.00"}
                          </Text>
                        ) : null}
                        {parameter?.[0]?.comm_value ? (
                          <Text
                            style={{
                              padding: 5,
                              textAlign: "center",
                              width: `${(100 - 10 * countParameter) / 5}%`,
                              fontSize: 9,
                              borderLeft: "1px solid black",
                            }}
                          >
                           $ {item?.data?.[0]?.comm_value ? Number(item?.data?.[0]?.comm_value).toFixed(2) : "0.00"}
                          </Text>
                        ) : null}
                        {parameter?.[0]?.comm_per ? (
                          <Text
                            style={{
                              padding: 5,
                              textAlign: "center",
                              width: `${(100 - 10 * countParameter) / 5}%`,
                              fontSize: 9,
                              borderLeft: "1px solid black",
                            }}
                          >
                            {item?.data?.[0]?.comm_per ? Number(item?.data?.[0]?.comm_per).toFixed(2) : "0.00"} %
                          </Text>
                        ) : null}
                        
                      </View>
                    </View>
                 
                </View>) : null
              })}
            {/* factory total calculation */}
            <View
              wrap={false}
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "0.5px solid black",
                borderBottom: "1px solid black",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 900,
                  width: `${10 * countParameter + 2}%`,
                  textAlign: "center",
                  padding: 5,
                }}
              >
                Factory Total
              </Text>
              {parameter?.[0]?.export_qty ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  {exportQtyArr?.reduce(
                    (totalQty, currentQty) => totalQty + Number(currentQty),
                    0
                  ) ?? 0}
                </Text>
              ) : null}
              
              {parameter?.[0]?.rdl_value ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  $ {rdlValueArr
                    ?.reduce((totalV, currentV) => totalV + Number(currentV), 0)
                    .toFixed(2) ?? 0}
                </Text>
              ) : null}
              {parameter?.[0]?.factory_value ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  $ {factoryValueArr
                    ?.reduce((totalV, currentV) => totalV + Number(currentV), 0)
                    .toFixed(2) ?? 0}
                </Text>
              ) : null}
              {parameter?.[0]?.comm_value ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  $ {commissionVArr
                    ?.reduce((totalV, currentV) => totalV + Number(currentV), 0)
                    .toFixed(2) ?? 0}
                </Text>
              ) : null}
              {parameter?.[0]?.comm_per ? (
                <Text
                  style={{
                    padding: 5,
                    textAlign: "center",
                    width: `${(100 - 10 * countParameter) / 5}%`,
                    fontSize: 10,
                    fontWeight: 900,
                    borderLeft: "1px solid black",
                  }}
                >
                  {/* {item?.data?.[0]?.comm_per} */}
                </Text>
              ) : null}
            </View>
            
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/asserts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/asserts/ARIALN.TTF",
    },
    {
      src: "/asserts/ARIALN.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 20,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Arial",
    minWidth: "120vw",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
