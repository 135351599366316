import axiosClient from "../axiosClient";

export async function bulkDeleteCostingItemDB(ids) {
  return await axiosClient.post(`/costing_items/bulk_delete`, ids);
}

export async function retrieveCostingItemsData() {
  return await axiosClient.get("/costing_items");
}

export async function addCostingItemDB(value) {
  return await axiosClient.post("/costing_items", value);
}

export async function deleteCostingItemDB(id) {
  return await axiosClient.delete(`/costing_items/${id}`);
}

export async function editCostingItemDB(value) {
  return await axiosClient.put(`/costing_items/${value.id}`, value);
}
