import axiosClient from "../axiosClient";
/*-------------------------------------------------*/
/*               CURRENCIES API CALLS              */
/*-------------------------------------------------*/

export async function retrieveCurrenciesData() {
    return await axiosClient.get("/currencies");
  }
  
  export async function addCurrencyDB(value) {
  
    console.log("add currency data", value);
  
    var bodyFormData = new FormData();
  
    bodyFormData.append("currency_name", value.currency_name);
    bodyFormData.append("symbol", value.symbol);
    bodyFormData.append("short_name", value.short_name);
    bodyFormData.append("created_by", value.created_by);
    bodyFormData.append("created_date", value.created_date);
    bodyFormData.append("modified_by", value.modified_by);
  
    return await axiosClient.post("/currencies", bodyFormData);
  }
  
  export async function deleteCurrencyDB(id) {
    return await axiosClient.delete(`/currencies/${id}`);
  }
  
  export async function bulkDeleteCurrencyDB(ids) {
    return await axiosClient.post(`/currencies/bulk_delete`, ids);
  }
  
  export async function editCurrencyDB(value) {
    
    console.log("edit currency data", value);
  
    var bodyFormData = new FormData();
  
    bodyFormData.append("currency_name", value.currency_name);
    bodyFormData.append("symbol", value.symbol);
    bodyFormData.append("short_name", value.short_name);
    bodyFormData.append("created_by", value.created_by);
    bodyFormData.append("created_date", value.created_date);
    bodyFormData.append("modified_by", value.modified_by);
    
    return await axiosClient.put(`/currencies/${value.id}`, bodyFormData);
  }