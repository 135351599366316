import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { CurrencyToWords } from "currency-to-words";
import React from "react";
import logo from "./Renaissence@3x.jpeg";

// Create styles
const Quixote = ({ invoiceData }) => {
  const tableHeaderData =
    invoiceData && invoiceData.length && invoiceData[0] ? invoiceData[0] : null;

  const tableBodyData =
    invoiceData &&
    invoiceData.length &&
    Object.keys(invoiceData[1]).length !== 0
      ? invoiceData[1]
      : null;

  const currencyName = invoiceData && invoiceData.length ? invoiceData[7] : {};

  const finalDestinationData =
    invoiceData &&
    invoiceData.length &&
    Object.keys(invoiceData[2]).length !== 0
      ? invoiceData[2]
      : null;

  const additionalClauseData =
    invoiceData && invoiceData.length && invoiceData[3] ? invoiceData[3] : [];
  const latePenaltyPolicyData =
    invoiceData && invoiceData.length && invoiceData[4] ? invoiceData[4] : [];
  const amendmentData =
    invoiceData && invoiceData.length && invoiceData[5] ? invoiceData[5] : [];

  const pTData =
    invoiceData && invoiceData.length && invoiceData[6] ? invoiceData[6] : [];

  // tableBodyDataRefine
  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : [];

  let hash = Object.create(null);

  let result =
    tableBodyDataRefine && tableBodyDataRefine.length !== 0
      ? tableBodyDataRefine.reduce(function (r, a) {
          console.log("hash", r, "current", a);
          if (!hash[a.dm_order_no]) {
            hash[a.dm_order_no] = { dm_order_no: a.dm_order_no, data: [] };
            r.push(hash[a.dm_order_no]);
          }
          hash[a.dm_order_no].data.push({
            order_date: a.order_date ? a.order_date : "",
            style_no: a.style_no ? a.style_no : "",
            product: a.product ? a.product : "",
            po_no: a.po_no ? a.po_no : "",
            color: a.color ? a.color : "",
            order_qty: a.order_qty ? a.order_qty : "",
            fob_rate: a.fob_rate ? a.fob_rate : "",
            fob_value: a.fob_value ? a.fob_value : "",
            destination: a.destination ? a.destination : "",
            delivery_date: a.delivery_date ? a.delivery_date : "",
          });
          return r;
        }, [])
      : [];

  // finalDestinationRefine

  const finalDestinationRefine = finalDestinationData
    ? Object.keys(finalDestinationData).map((key) => finalDestinationData[key])
    : [];

  const pTDataRefine = pTData
    ? Object.keys(pTData).map((key) => pTData[key])
    : [];

  const uniq = new Set(
    finalDestinationRefine && finalDestinationRefine.length
      ? finalDestinationRefine.map((e) => JSON.stringify(e))
      : []
  );

  const finalDestinationRes = Array.from(uniq ? uniq : {}).map((e) =>
    JSON.parse(e)
  );

  // additionalClauseRefine
  const additionalClauseRefine = additionalClauseData
    ? Object.keys(additionalClauseData).map((key) => additionalClauseData[key])
    : [];
  // latePenaltyPolicyDataRefine
  const latePenaltyPolicyDataRefine = latePenaltyPolicyData
    ? Object.keys(latePenaltyPolicyData).map(
        (key) => latePenaltyPolicyData[key]
      )
    : [];

  const grandtotalOrderQty =
    tableBodyDataRefine &&
    tableBodyDataRefine.length &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.order_qty),
      0
    );

  // totalFobValue
  // const totalFobValue = null;
  const grandtotalValue =
    tableBodyDataRefine &&
    tableBodyDataRefine.length &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.fob_value),
      0
    );

  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  // function inWords(num) {
  //   if ((num = num.toString()).length > 9) return "overflow";
  //   let n = ("000000000" + num)
  //     .substr(-9)
  //     .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  //   if (!n) return;
  //   var str = "";
  //   str +=
  //     n[1] != 0
  //       ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
  //       : "";
  //   str +=
  //     n[2] != 0
  //       ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lac "
  //       : "";
  //   str +=
  //     n[3] != 0
  //       ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
  //       : "";
  //   str +=
  //     n[4] != 0
  //       ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
  //       : "";
  //   str +=
  //     n[5] != 0
  //       ? (str != "" ? "and " : "") +
  //         (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
  //         ""
  //       : "";
  //   return str
  //     .toLowerCase()
  //     .split(" ")
  //     .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
  //     .join(" ");
  // }

  const totalValueInword = CurrencyToWords(
    grandtotalValue ? grandtotalValue.toFixed(2) : 0
  );

  //   function numberWithCommas(x) {
  //     return x.toString().split(".")[0].length > 3
  //       ? x
  //           .toString()
  //           .substring(0, x.toString().split(".")[0].length - 3)
  //           .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
  //           "," +
  //           x.toString().substring(x.toString().split(".")[0].length - 3)
  //       : x.toString();
  //   }
  // numberWithCommas(parseInt(item.amount))

  let count = 0;

  const BuyerData = ({ i2 }) => {
    return (
      <View
        wrap={false}
        break={count > 13}
        style={{
          borderBottom: "1px solid black",
          borderLeft: "1px solid black",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.style_no}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.product}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.po_no}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "14%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.color}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "9%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.order_qty}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "9%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : ""}{" "}
          {Number(i2?.fob_rate).toFixed(2)}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {currencyName && currencyName?.["0"]?.["primary_currency_symbol"]
            ? currencyName?.["0"]?.["primary_currency_symbol"]
            : ""}{" "}
          {Number(i2?.fob_value).toFixed(2)}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "11%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.destination}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {new Date(i2 ? i2?.delivery_date : null).toLocaleDateString([
            "es-CL",
            "id",
          ])}
        </Text>
      </View>
    );
  };

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}

        <View>
          <View
            fixed
            style={{
              justifyContent: "center",
              textAlign: "center",
              marginTop: "-20px",
              marginBottom: "20px",
            }}
          >
            <Image
              fixed
              style={{
                width: "550px",
                height: "75px",
                textAlign: "center",
                margin: "auto",
              }}
              src={logo}
            />
          </View>

          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "17px",
                borderBottom: "1px solid black",
                width: "140px",
                fontWeight: "bold",
              }}
            >
              Sales Contract
            </Text>
          </View>

          {/* Header Info Part-1*/}
          <View>
            {/* SC no && date */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                }}
              >
                SALES CONTRACT NO :{" "}
                {tableHeaderData &&
                tableHeaderData?.["0"]?.["sales_contract_no"]
                  ? tableHeaderData?.["0"]?.["sales_contract_no"]
                  : ""}
              </Text>
              <Text
                style={{
                  fontSize: "9px",
                  width: 80,
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <Text style={{ fontWeight: "bold" }}>Date :</Text>{" "}
                <Text>
                  {new Date(
                    tableHeaderData
                      ? tableHeaderData?.["0"]?.["sales_contract_date"]
                      : null
                  ).toLocaleDateString(["es-CL", "id"])}
                </Text>
              </Text>
            </View>
            {/* Amendment no */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
              }}
            >
              {amendmentData?.["0"]?.["amd_no"] ? (
                <Text style={{ fontSize: "9px", fontWeight: "bold" }}>
                  AMENDMENT NO :{" "}
                  {amendmentData && amendmentData?.["0"]?.["amd_no"]}
                </Text>
              ) : (
                ""
              )}
              {amendmentData?.["0"]?.["amd_date"] ? (
                <Text
                  style={{
                    fontSize: "9px",
                    width: 80,
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Date :</Text>
                  <Text>
                    {new Date(
                      amendmentData ? amendmentData?.["0"]?.["amd_date"] : null
                    ).toLocaleDateString(["es-CL", "id"])}
                  </Text>
                </Text>
              ) : (
                ""
              )}
            </View>

            {/* buyer name && bank */}
            <View
              style={{
                flexDirection: "row",
                marginTop: 25,
                justifyContent: "space-between",
                height: `${
                  tableHeaderData?.["0"]?.["buyer_address"] ? "auto" : "60px"
                }`,
              }}
            >
              <View style={{ width: "220px" }}>
                <Text
                  style={{
                    fontSize: "9px",
                    marginBottom: 3,
                    fontWeight: "bold",
                  }}
                >
                  BUYER NAME :
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  {tableHeaderData && tableHeaderData?.["0"]?.["buyer_name"]
                    ? tableHeaderData?.["0"]?.["buyer_name"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  {tableHeaderData && tableHeaderData?.["0"]?.["buyer_address"]
                    ? tableHeaderData?.["0"]?.["buyer_address"]
                    : ""}
                </Text>

                <Text
                  style={{
                    fontSize: "9px",
                    marginTop: 7,
                    marginBottom: 3,
                    fontWeight: "bold",
                  }}
                >
                  CONSIGNEE :
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  {tableHeaderData && tableHeaderData?.["0"]?.["consignee_name"]
                    ? tableHeaderData?.["0"]?.["consignee_name"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  {tableHeaderData &&
                  tableHeaderData?.["0"]?.["consignee_address"]
                    ? tableHeaderData?.["0"]?.["consignee_address"]
                    : ""}
                </Text>
              </View>
              <View style={{ width: "240px" }}>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    marginBottom: 3,
                  }}
                >
                  BUYERS BANK :
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  {tableHeaderData && tableHeaderData?.["0"]?.["buyer_bank"]
                    ? tableHeaderData?.["0"]?.["buyer_bank"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  {tableHeaderData &&
                  tableHeaderData?.["0"]?.["buyer_bakaddress"]
                    ? tableHeaderData?.["0"]?.["buyer_bakaddress"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  Acc Name :{" "}
                  {tableHeaderData &&
                  tableHeaderData?.["0"]?.["buyer_bank_acname"]
                    ? tableHeaderData?.["0"]?.["buyer_bank_acname"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  Acc No. :{" "}
                  {tableHeaderData && tableHeaderData?.["0"]?.["buyer_bakac_no"]
                    ? tableHeaderData?.["0"]?.["buyer_bakac_no"]
                    : []}
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  SWIFT :{" "}
                  {tableHeaderData &&
                  tableHeaderData?.["0"]?.["buyer_swift_code"]
                    ? tableHeaderData?.["0"]?.["buyer_swift_code"]
                    : ""}
                </Text>
              </View>
            </View>

            {/* supplier name && bank*/}
            <View
              style={{
                flexDirection: "row",
                marginTop: 25,
                justifyContent: "space-between",
                height: `${
                  tableHeaderData?.["0"]?.["factory_name"] ? "auto" : "80px"
                }`,
              }}
            >
              <View style={{ width: "220px", height: "auto" }}>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    marginBottom: 3,
                  }}
                >
                  SUPPLIER/ MANUFACTURER NAME:
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  {tableHeaderData && tableHeaderData?.["0"]?.["factory_name"]
                    ? tableHeaderData?.["0"]?.["factory_name"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  {tableHeaderData &&
                  tableHeaderData?.["0"]?.["factory_address"]
                    ? tableHeaderData?.["0"]?.["factory_address"]
                    : ""}
                </Text>
              </View>

              <View style={{ width: "240px", height: "auto" }}>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    marginBottom: 3,
                  }}
                >
                  SUPPLIER/ MANUFACTURER BANK:
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  {tableHeaderData && tableHeaderData?.["0"]?.["supplier_bank"]
                    ? tableHeaderData?.["0"]?.["supplier_bank"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  {tableHeaderData &&
                  tableHeaderData?.["0"]?.["supplier_bakaddress"]
                    ? tableHeaderData?.["0"]?.["supplier_bakaddress"]
                    : " "}
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  Acc Name :{" "}
                  {tableHeaderData &&
                  tableHeaderData?.["0"]?.["supplier_acname"]
                    ? tableHeaderData?.["0"]?.["supplier_acname"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  Acc No. :{" "}
                  {tableHeaderData && tableHeaderData?.["0"]?.["supplier_acno"]
                    ? tableHeaderData?.["0"]?.["supplier_acno"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  SWIFT :{" "}
                  {tableHeaderData &&
                  tableHeaderData?.["0"]?.["supplier_swift_code"]
                    ? tableHeaderData?.["0"]?.["supplier_swift_code"]
                    : ""}
                </Text>
              </View>
            </View>

            {/* buyer nominated name && bank*/}
            <View
              style={{
                flexDirection: "row",
                marginTop: 25,
                justifyContent: "space-between",
                height: `${
                  tableHeaderData?.["0"]?.["dm_bank"] ? "auto" : "60px"
                }`,
              }}
            >
              <View style={{ width: "220px" }}>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    marginBottom: 3,
                  }}
                >
                  BUYERS NOMINATED AGENT NAME:
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  Renaissance Designs Limited
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  Uttara, Dhaka, Bangladesh.
                </Text>
              </View>
              <View style={{ width: "240px" }}>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    marginBottom: 3,
                  }}
                >
                  BANK DETAILS:
                </Text>
                <Text style={{ fontSize: "9px", paddingBottom: 3 }}>
                  {tableHeaderData && tableHeaderData?.["0"]?.["dm_bank"]
                    ? tableHeaderData?.["0"]?.["dm_bank"]
                    : ""}
                  {tableHeaderData && tableHeaderData?.["0"]?.["dm_branch_name"]
                    ? ` (${tableHeaderData?.["0"]?.["dm_branch_name"]})`
                    : ""}
                </Text>

                <Text style={{ fontSize: "9px", paddingBottom: 3 }}>
                  Acc No. :{" "}
                  {tableHeaderData && tableHeaderData?.["0"]?.["dm_acno"]
                    ? tableHeaderData?.["0"]?.["dm_acno"]
                    : ""}
                </Text>
                <Text style={{ fontSize: "9px", paddingBottom: 3 }}>
                  SWIFT :{" "}
                  {tableHeaderData && tableHeaderData?.["0"]?.["dm_swift_code"]
                    ? tableHeaderData?.["0"]?.["dm_swift_code"]
                    : ""}
                </Text>
              </View>
            </View>

            {/* contact person*/}
            <View
              style={{
                marginTop: 25,
              }}
            >
              <View style={{ width: "220px" }}>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    marginBottom: 3,
                  }}
                >
                  CONTACT PERSON : ROBIN BARUA
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  Contact Number : +8801713388834
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  PABX : +88 0967 8882211, +88 02 58957475, Ext-344
                </Text>
                <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                  Email : robin.barua@renaissance.com.bd
                </Text>
              </View>
            </View>

            {/* Header Info Part-5*/}
            <View
              style={{
                marginTop: 25,
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  marginBottom: 3,
                }}
              >
                THIS PURCHASE CONTRACT HAS BEEN MADE WITH BUYER AND SUPPLIER AS
                PER BELOW TERMS AND CONDITIONS:
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "9px",
                      fontWeight: "bold",
                      marginBottom: 3,
                    }}
                  >
                    NEGOTIATION :
                  </Text>
                </View>
                <View style={{ width: "240px" }}>
                  <Text style={{ fontSize: "9px", paddingBottom: 3 }}>
                    {tableHeaderData && tableHeaderData?.["0"]?.["neg_bank"]
                      ? tableHeaderData?.["0"]?.["neg_bank"]
                      : ""}
                    {tableHeaderData &&
                    tableHeaderData?.["0"]?.["neg_branch_name"]
                      ? ` (${tableHeaderData?.["0"]?.["neg_branch_name"]})`
                      : ""}
                  </Text>
                  <Text style={{ fontSize: "9px", paddingBottom: 3 }}>
                    {tableHeaderData &&
                    tableHeaderData?.["0"]?.["neg_bakaddress"]
                      ? tableHeaderData?.["0"]?.["neg_bakaddress"]
                      : " "}
                  </Text>

                  <Text style={{ fontSize: "9px", paddingBottom: 3 }}>
                    Acc Name :{" "}
                    {tableHeaderData && tableHeaderData?.["0"]?.["neg_acname"]
                      ? tableHeaderData?.["0"]?.["neg_acname"]
                      : ""}
                  </Text>
                  <Text style={{ fontSize: "9px", paddingBottom: 3 }}>
                    Acc No. :{" "}
                    {tableHeaderData && tableHeaderData?.["0"]?.["neg_acno"]
                      ? tableHeaderData?.["0"]?.["neg_acno"]
                      : ""}
                  </Text>
                  <Text style={{ fontSize: "9px", paddingBottom: 3 }}>
                    SWIFT :{" "}
                    {tableHeaderData &&
                    tableHeaderData?.["0"]?.["neg_swift_code"]
                      ? tableHeaderData?.["0"]?.["neg_swift_code"]
                      : ""}
                  </Text>
                  <Text style={{ fontSize: "9px", paddingBottom: 3 }}>
                    Or Any Bank of Bangladesh
                  </Text>
                </View>
              </View>
            </View>

            {/* negotiation bank part*/}
            <View style={{ width: "220px" }}>
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  marginBottom: 3,
                }}
              >
                PAYMENT TERMS :{" "}
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  {pTDataRefine && pTDataRefine.length
                    ? pTDataRefine.map((pt) => pt.p_term)
                    : null}
                </Text>
              </Text>
              <Text style={{ fontSize: "9px", marginBottom: 3 }}>
                {tableHeaderData && tableHeaderData?.["0"]?.["payment_terms"]}
              </Text>
            </View>
          </View>

          {/* partial shipment and trans*/}

          <View
            style={{
              marginTop: 25,
            }}
          ></View>
          <View>
            <Text
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                marginBottom: 3,
              }}
            >
              PARTIAL SHIPMENT AND TRANS SHIPMENT :{" "}
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: "normal",
                }}
              >
                {tableHeaderData &&
                tableHeaderData?.["0"]?.["partial_shipment"] === "A"
                  ? "ALLOWED"
                  : "NOT ALLOWED"}
              </Text>
            </Text>
          </View>

          <View
            wrap={false}
            style={{
              flexDirection: "row",
              height: "auto",
              paddingBottom: 3,
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                marginRight: 5,
              }}
            >
              PORT OF LOADING :
            </Text>
            <View style={{ width: "450px" }}>
              <Text style={{ fontSize: "9px" }}>
                {tableHeaderData && tableHeaderData?.["0"]
                  ? tableHeaderData?.["0"]?.["loding_port"]
                  : ""}
              </Text>
            </View>
          </View>

          <View
            wrap={false}
            style={{
              flexDirection: "row",
              height: "auto",
              marginBottom: 3,
              marginTop: 3,
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                marginRight: 5,
              }}
            >
              PORT OF DISCHARGE :
            </Text>
            <View style={{ width: "450px" }}>
              <Text
                style={{
                  fontSize: "9px",
                  flexDirection: "row",
                  height: "auto",
                }}
              >
                ANY PORT OF{" "}
                {finalDestinationRes && finalDestinationRes.length
                  ? finalDestinationRes.map((item, index) => (
                      <Text key={index} style={{ fontSize: "9px" }}>
                        {item?.port_name}
                        {index === finalDestinationRes.length - 1
                          ? null
                          : ","}{" "}
                      </Text>
                    ))
                  : null}
              </Text>
            </View>
          </View>

          <View
            wrap={false}
            style={{
              flexDirection: "row",
              height: "auto",
              marginBottom: 3,
              marginTop: 3,
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                marginRight: 5,
              }}
            >
              FINAL DESTINATION :
            </Text>
            <View style={{ width: "450px" }}>
              <Text
                style={{
                  flexDirection: "row",
                }}
              >
                {finalDestinationRes && finalDestinationRes.length
                  ? finalDestinationRes.map((item, index) => (
                      <Text key={index} style={{ fontSize: "9px" }}>
                        {item?.port_name}
                        {index === finalDestinationRes.length - 1
                          ? null
                          : ","}{" "}
                      </Text>
                    ))
                  : null}
              </Text>
            </View>
          </View>

          <View
            wrap={false}
            style={{
              flexDirection: "row",
              height: "auto",
              marginBottom: 3,
              marginTop: 6,
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                marginRight: 5,
              }}
            >
              FREIGHT TERMS :{" "}
            </Text>
            <Text style={{ fontSize: "9px", width: "450px" }}>
              {tableHeaderData && tableHeaderData?.["0"]
                ? tableHeaderData?.["0"]?.["frieght_terms"]
                : ""}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              height: "auto",
              marginBottom: 3,
              marginTop: 3,
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                marginRight: 5,
              }}
            >
              DATE AND PLACE OF EXPIRY :
            </Text>
            <Text style={{ fontSize: "9px" }}>
              {new Date(
                tableHeaderData ? tableHeaderData?.["0"]?.["expiry_date"] : null
              ).toLocaleDateString(["es-CL", "id"])}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              height: "auto",
              marginBottom: 3,
              marginTop: 3,
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                marginRight: 5,
              }}
            >
              LAST DATE OF SHIPMENT :
            </Text>
            <Text style={{ fontSize: "9px" }}>
              {new Date(
                tableHeaderData
                  ? tableHeaderData?.["0"]?.["shipment_date"]
                  : null
              ).toLocaleDateString(["es-CL", "id"])}
            </Text>
          </View>

          {/* TABLE PART */}

          <View style={{ marginTop: "30px" }}>
            <Text
              style={{
                fontSize: "10px",
                fontWeight: "bold",
                marginBottom: 2,
              }}
            >
              GOODS DETAILS :
            </Text>
            <View
              wrap={false}
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
              fixed
            >
              <Text
                style={{
                  padding: "9px 3px 0 3px",
                  width: "12%",
                  fontSize: "9px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Style No
              </Text>
              <Text
                style={{
                  padding: "9px 3px 0 3px",
                  width: "11%",
                  fontSize: "9px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Description
              </Text>

              <Text
                style={{
                  padding: "9px 3px 0 3px",
                  width: "12%",
                  fontSize: "9px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                PO No.
              </Text>
              <Text
                style={{
                  padding: "9px 3px 0 3px",
                  fontSize: "9px",
                  width: "14%",
                  fontWeight: "bold",
                  alignItems: "center",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Color
              </Text>
              <Text
                style={{
                  padding: "9px 3px",
                  width: "9%",
                  borderRight: "1px solid black",
                  fontSize: "9px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Qty/Unit
              </Text>
              <Text
                style={{
                  padding: "9px 3px",
                  fontSize: "9px",
                  width: "9%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Price/Unit
              </Text>
              <Text
                style={{
                  padding: "4px 3px",
                  fontSize: "9px",
                  width: "12%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Total Price Per Order Qty
              </Text>
              <Text
                style={{
                  padding: "9px 3px",
                  fontSize: "9px",
                  width: "11%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Destination
              </Text>
              <Text
                style={{
                  padding: "4px 0px",
                  fontSize: "9px",
                  width: "12%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Delivery Date (Ex-Factory)
              </Text>
            </View>

            <View>
              {result && result.length !== 0
                ? result.map((item, itemIndex) => {
                    console.log("item.data", item.data);

                    const subtotalOrderQty =
                      item.data && item.data.length
                        ? item.data.reduce(
                            (totalValue, currentValue) =>
                              totalValue + Number(currentValue?.order_qty),
                            0
                          )
                        : 0;

                    const subtotalValue =
                      item.data && item.data.length
                        ? item.data.reduce(
                            (totalValue, currentValue) =>
                              totalValue + Number(currentValue?.fob_value),
                            0
                          )
                        : null;

                    return (
                      <View
                        key={itemIndex}
                        style={{
                          // flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <View wrap={false}>
                          <Text
                            style={{
                              padding: "5px 0 5px 10px",
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "black",
                              height: "auto",
                              width: "100%",
                              border: "1px solid black",
                            }}
                          >
                            {item?.dm_order_no}
                          </Text>
                        </View>
                        {result && result.length && item.data.length
                          ? item.data.map((i2, i) => {
                              count = count + 1;
                              return (
                                <View>
                                  <BuyerData key={i.order_qty} i2={i2} />
                                </View>
                              );
                            })
                          : null}
                        <View
                          wrap={false}
                          style={{
                            borderBottom: "1px solid black",
                            borderLeft: "1px solid black",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <Text
                            style={{
                              padding: "5px",
                              fontSize: "9px",
                              height: "auto",
                              width: "12%",
                              fontWeight: "bold",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {"Sub Total"}
                          </Text>
                          <Text
                            style={{
                              padding: "5px",
                              fontSize: "8px",
                              height: "auto",
                              width: "11%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {""}
                          </Text>
                          <Text
                            style={{
                              padding: "5px",
                              fontSize: "8px",
                              height: "auto",
                              width: "12%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {""}
                          </Text>
                          <Text
                            style={{
                              padding: "5px",
                              fontSize: "8px",
                              height: "auto",
                              width: "14%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {""}
                          </Text>
                          <Text
                            style={{
                              padding: "5px",
                              fontSize: "8px",
                              height: "auto",
                              fontWeight: "bold",
                              width: "9%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {subtotalOrderQty}
                          </Text>
                          <Text
                            style={{
                              padding: "5px",
                              fontSize: "8px",
                              height: "auto",
                              width: "9%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {""}
                          </Text>
                          <Text
                            style={{
                              padding: "5px",
                              fontSize: "8px",
                              height: "auto",
                              fontWeight: "bold",
                              width: "12%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {currencyName &&
                            currencyName?.["0"]?.["primary_currency_symbol"]
                              ? currencyName?.["0"]?.["primary_currency_symbol"]
                              : ""}{" "}
                            {Number(subtotalValue).toFixed(2)}
                          </Text>
                          <Text
                            style={{
                              padding: "5px",
                              fontSize: "8px",
                              height: "auto",
                              width: "11%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {""}
                          </Text>
                          <Text
                            style={{
                              padding: "5px",
                              fontSize: "8px",
                              height: "auto",
                              width: "12%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {""}
                          </Text>
                        </View>
                      </View>
                    );
                  })
                : null}
              <View
                wrap={false}
                style={{
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    padding: "5px",
                    fontSize: "9px",
                    height: "auto",
                    width: "12%",
                    fontWeight: "bold",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {"Grand Total"}
                </Text>
                <Text
                  style={{
                    padding: "5px",
                    fontSize: "8px",
                    height: "auto",
                    width: "11%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {""}
                </Text>
                <Text
                  style={{
                    padding: "5px",
                    fontSize: "8px",
                    height: "auto",
                    width: "12%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {""}
                </Text>
                <Text
                  style={{
                    padding: "5px",
                    fontSize: "8px",
                    height: "auto",
                    width: "14%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {""}
                </Text>
                <Text
                  style={{
                    padding: "5px",
                    fontSize: "8px",
                    height: "auto",
                    fontWeight: "bold",
                    width: "9%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {grandtotalOrderQty}
                </Text>
                <Text
                  style={{
                    padding: "5px",
                    fontSize: "8px",
                    height: "auto",
                    width: "9%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {""}
                </Text>
                <Text
                  style={{
                    padding: "5px",
                    fontSize: "8px",
                    height: "auto",
                    fontWeight: "bold",
                    width: "12%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {currencyName &&
                  currencyName?.["0"]?.["primary_currency_symbol"]
                    ? currencyName?.["0"]?.["primary_currency_symbol"]
                    : ""}{" "}
                  {grandtotalValue.toFixed(2)}
                </Text>
                <Text
                  style={{
                    padding: "5px",
                    fontSize: "8px",
                    height: "auto",
                    width: "11%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {""}
                </Text>
                <Text
                  style={{
                    padding: "5px",
                    fontSize: "8px",
                    height: "auto",
                    width: "12%",
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {""}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 20, marginBottom: 25 }}>
            <Text
              style={{ fontSize: "9px", fontWeight: "bold", paddingBottom: 10 }}
            >
              In Words (
              {currencyName && currencyName?.["0"]?.["primary_currency"]
                ? (currencyName?.["0"]?.["primary_currency"]).toUpperCase()
                : ""}
              ) :{" "}
              <Text style={{ fontSize: "9px", fontWeight: "normal" }}>
                {totalValueInword?.replace(/dollars/g, "").toUpperCase()} ONLY.
              </Text>
            </Text>
          </View>

          <View>
            {additionalClauseRefine &&
            additionalClauseRefine.length &&
            additionalClauseRefine[0]?.["slno"] !== null ? (
              <Text style={{ fontSize: "9px", fontWeight: "bold" }}>
                ADDITIONAL CLAUSE :
              </Text>
            ) : (
              " "
            )}

            {additionalClauseRefine &&
              additionalClauseRefine.length &&
              additionalClauseRefine.map((item, index) => (
                <View key={index} style={{ marginLeft: 20 }}>
                  <Text style={{ fontSize: "9px" }}>
                    {`${item?.slno}.`}
                    {item.description}
                  </Text>
                </View>
              ))}

            {latePenaltyPolicyDataRefine &&
            latePenaltyPolicyDataRefine.length &&
            latePenaltyPolicyDataRefine[0]?.["description"] ? (
              <Text
                style={{ fontWeight: "bold", fontSize: "9px", marginTop: 20 }}
              >
                LATE PENALTY POLICY :
              </Text>
            ) : (
              ""
            )}

            <View>
              {latePenaltyPolicyDataRefine &&
                latePenaltyPolicyDataRefine.length &&
                latePenaltyPolicyDataRefine.map((item, index) => (
                  <View key={index} style={{ marginLeft: 20 }}>
                    <Text style={{ fontSize: "9px" }}>
                      {`${item?.slno}.`}
                      {item.description}
                    </Text>
                  </View>
                ))}
            </View>
            {tableHeaderData &&
              tableHeaderData?.["0"]?.["entry_type"] === "LC" && (
                <Text
                  style={{ marginTop: 20, fontWeight: "bold", fontSize: "9px" }}
                >
                  N.B : THIS CONTRACT SHALL BE REPLACED BY EXPORT TT.
                </Text>
              )}
          </View>

          {/* Signature part */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 100,
            }}
          >
            <View>
              <View>
                <Text
                  style={{
                    backgroundColor: "black",
                    height: "1px",
                    width: "220px",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    marginBottom: 3,
                  }}
                >
                  AUTHORISED SIGNATURE
                </Text>
                <Text style={{ fontSize: "8px", marginBottom: 3 }}>
                  Renaissance Designs Limited
                </Text>
                <Text style={{ fontSize: "8px", marginBottom: 3 }}>
                  (Agent of{" "}
                  {tableHeaderData && tableHeaderData?.["0"]?.["buyer_name"]})
                </Text>
                <Text style={{ fontSize: "8px" }}>Date :</Text>
              </View>
            </View>
            <View>
              <View>
                <Text
                  style={{
                    backgroundColor: "black",
                    height: "1px",
                    width: "190px",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    marginBottom: 3,
                  }}
                >
                  AUTHORIZED SIGNATURE
                </Text>
                <Text style={{ fontSize: "8px", marginBottom: 3 }}>
                  {tableHeaderData && tableHeaderData?.["0"]?.["factory_name"]}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Arial",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
