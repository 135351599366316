import {
  ADD_COSTING_ITEM_GROUPS,
  ADD_COSTING_ITEM_GROUPS_ERROR,
  ADD_COSTING_ITEM_GROUPS_OK,
  BEGIN_EDIT_COSTING_ITEM_GROUPS,
  BEGIN_COSTING_ITEM_GROUPS_DOWNLOAD,
  RETRIEVE_COSTING_ITEM_GROUPS_BULK_DELETE,
  RETRIEVE_COSTING_ITEM_GROUPS_DELETE,
  RETRIEVE_COSTING_ITEM_GROUPS_EDIT,
  COSTING_ITEM_GROUPS_BULK_DELETED_ERROR,
  COSTING_ITEM_GROUPS_BULK_DELETED_OK,
  COSTING_ITEM_GROUPS_DELETED_ERROR,
  COSTING_ITEM_GROUPS_DELETED_OK,
  COSTING_ITEM_GROUPS_DOWNLOAD_ERROR,
  COSTING_ITEM_GROUPS_DOWNLOAD_OK,
  COSTING_ITEM_GROUPS_EDITED_ERROR,
  COSTING_ITEM_GROUPS_EDITED_OK,
} from "../../constants/modules/costingItemGroups";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteCostingItemGroupAction = (item) => ({
  type: RETRIEVE_COSTING_ITEM_GROUPS_BULK_DELETE,
  payload: true,
  costing_item_group: item,
});

export const bulkDeleteCostingItemGroupOkAction = () => ({
  type: COSTING_ITEM_GROUPS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteCostingItemGroupErrorAction = (state) => ({
  type: COSTING_ITEM_GROUPS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadCostingItemGroupsAction = () => ({
  type: BEGIN_COSTING_ITEM_GROUPS_DOWNLOAD,
  payload: true,
});

export const downloadCostingItemGroupsOkAction = (items) => ({
  type: COSTING_ITEM_GROUPS_DOWNLOAD_OK,
  payload: items,
});

export const downloadCostingItemGroupsErrorAction = () => ({
  type: COSTING_ITEM_GROUPS_DOWNLOAD_ERROR,
  payload: true,
});

export const addCostingItemGroupAction = (item) => ({
  type: ADD_COSTING_ITEM_GROUPS,
  payload: true,
  costing_item_group: item,
});

export const addCostingItemGroupOkAction = () => ({
  type: ADD_COSTING_ITEM_GROUPS_OK,
  payload: true,
});

export const addCostingItemGroupErrorAction = (state) => ({
  type: ADD_COSTING_ITEM_GROUPS_ERROR,
  payload: state,
});

export const deleteCostingItemGroupAction = (id) => ({
  type: RETRIEVE_COSTING_ITEM_GROUPS_DELETE,
  payload: id,
});

export const deleteCostingItemGroupOkAction = () => ({
  type: COSTING_ITEM_GROUPS_DELETED_OK,
});

export const deleteCostingItemGroupErrorAction = () => ({
  type: COSTING_ITEM_GROUPS_DELETED_ERROR,
  payload: true,
});

export const retrieveCostingItemGroupAction = (item) => ({
  type: RETRIEVE_COSTING_ITEM_GROUPS_EDIT,
  payload: item,
});

export const editCostingItemGroupAction = (item) => ({
  type: BEGIN_EDIT_COSTING_ITEM_GROUPS,
  costing_item_group: item,
});

export const editCostingItemGroupOkAction = (item) => ({
  type: COSTING_ITEM_GROUPS_EDITED_OK,
  payload: item,
});

export const editCostingItemGroupErrorAction = () => ({
  type: COSTING_ITEM_GROUPS_EDITED_ERROR,
  payload: true,
});
