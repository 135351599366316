import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";
import { CurrencyToWords } from "currency-to-words";

const fakeData = [
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
  {
    fdbc_no: "015OBD23155REN",
    fdbc_date: "2023-03-19",
    dm_invoice_no: "RKL0462023",
    dm_invoice_date: "2023-05-07",
    dm_total_value: "7.10",
    invoice_qty: 30,
  },
];

const fakeDate2 = [
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 60,
    invoice_value: "14.80",
  },
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 30,
    invoice_value: "14.80",
  },
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 30,
    invoice_value: "14.80",
  },
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 30,
    invoice_value: "14.80",
  },
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 30,
    invoice_value: "14.80",
  },
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 30,
    invoice_value: "14.80",
  },
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 30,
    invoice_value: "14.80",
  },
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 30,
    invoice_value: "14.80",
  },
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 30,
    invoice_value: "14.80",
  },
  {
    factory_name: "REAZ KNITWEAR LTD",
    invoice_date: "2023-05-07",
    invoice_no: "RKL0452023",
    invoice_qty: 30,
    invoice_value: "14.80",
  },
];

// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const reportHeaderData =
    invoiceData && invoiceData.length ? invoiceData[0] : {};
  const tableBodyData1 =
    invoiceData && invoiceData.length ? invoiceData[1] : {};
  const tableBodyData2 =
    invoiceData && invoiceData.length ? invoiceData[2] : {};

  const commPercentage =
    invoiceData && invoiceData.length ? invoiceData[3] : {};

  console.log("tableBodyData1", invoiceData);

  // extract month from doc-date
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = reportHeaderData && reportHeaderData?.["0"]?.["doc_date"];
  const formatDate = new Date(date);
  let monthName = month[formatDate.getMonth()];

  const refineTableBodyData1 = tableBodyData1
    ? Object.keys(tableBodyData1).map((key) => tableBodyData1[key])
    : [];

  const tableBodyDataRefine = tableBodyData2
    ? Object.keys(tableBodyData2).map((key) => tableBodyData2[key])
    : [];

  const totalOrderQty1 =
    refineTableBodyData1 &&
    refineTableBodyData1.length &&
    refineTableBodyData1.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.invoice_qty),
      0
    );
  const totalOrderQty2 =
    tableBodyDataRefine &&
    tableBodyDataRefine.length &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.invoice_qty),
      0
    );

  // totalFobValue1
  const totalFobValue1 =
    refineTableBodyData1 &&
    refineTableBodyData1.length &&
    refineTableBodyData1.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.dm_total_value),
      0
    );

  // totalFobValue2
  const totalFobValue2 =
    tableBodyDataRefine &&
    tableBodyDataRefine.length &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.invoice_value),
      0
    );

  const totalCommValue = totalFobValue1 - totalFobValue2;

  const totalValueInword = CurrencyToWords(
    totalCommValue ? totalCommValue.toFixed(2) : 0
  );

  const BDTTaka =
    totalCommValue *
    (reportHeaderData
      ? Number(reportHeaderData?.["0"]?.["conversion_rate"])
      : 1);

  const totalBDTValueInword = CurrencyToWords(BDTTaka ? BDTTaka.toFixed(2) : 0);

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  let preCount = 1;
  let countPage = 1;

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
            marginTop: -20,
          }}
        >
          <Image
            style={{
              width: "550px",
              height: "75px",
              textAlign: "center",
              margin: "auto",
            }}
            src={logo}
          />
          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                width: "200px",
                padding: "6px",
              }}
            >
              COMMISSION INVOICE
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-60px",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: "9px" }}>Print Date : {defaultValue}</Text>
            <Text style={{ fontSize: "9px" }}>
              Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
                       
            </Text>
          </View>
        </View>

        {/* Header Info */}
        <View
          style={{
            height: "80px",
            marginTop: 10,
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 3 }}
            >
              LC/ RDL Ref :{" "}
              <Text style={{ fontSize: "10px", fontWeight: "normal" }}>
                {reportHeaderData && reportHeaderData?.["0"]?.["lc_no"]
                  ? reportHeaderData?.["0"]?.["lc_no"]
                  : ""}
              </Text>
            </Text>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 3 }}
            >
              Bank Name :{" "}
              <Text style={{ fontSize: "10px", fontWeight: "normal" }}>
                {reportHeaderData && reportHeaderData?.["0"]?.["bank_name"]
                  ? reportHeaderData?.["0"]?.["bank_name"]
                  : ""}
              </Text>
            </Text>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 3 }}
            >
              Buyer Name :{" "}
              <Text style={{ fontSize: "10px", fontWeight: "normal" }}>
                {reportHeaderData && reportHeaderData?.["0"]?.["buyer_name"]
                  ? reportHeaderData?.["0"]?.["buyer_name"]
                  : ""}
              </Text>
            </Text>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 3 }}
            >
              EX-Factory Date :{" "}
              <Text style={{ fontSize: "10px", fontWeight: "normal" }}>
                {reportHeaderData && reportHeaderData?.["0"]?.["exfactory_date"]
                  ? reportHeaderData?.["0"]?.["exfactory_date"]
                  : ""}
              </Text>
            </Text>
          </View>

          <View>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 3 }}
            >
              Ref No :{" "}
              <Text style={{ fontSize: "10px", fontWeight: "normal" }}>
                {reportHeaderData && reportHeaderData?.["0"]?.["ref_no"]
                  ? reportHeaderData?.["0"]?.["ref_no"]
                  : ""}
              </Text>
            </Text>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 3 }}
            >
              Date :{" "}
              <Text style={{ fontSize: "10px", fontWeight: "normal" }}>
                {reportHeaderData && reportHeaderData?.["0"]?.["doc_date"]
                  ? reportHeaderData?.["0"]?.["doc_date"]
                  : ""}
              </Text>
            </Text>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 3 }}
            >
              Month :{" "}
              <Text style={{ fontSize: "10px", fontWeight: "normal" }}>
                {monthName ? monthName : ""}
              </Text>
            </Text>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 3 }}
            >
              Bank Ref. No:{" "}
              <Text style={{ fontSize: "10px", fontWeight: "normal" }}>
                {tableBodyData1 && tableBodyData1?.[0]?.["fdbc_no"]
                  ? tableBodyData1?.[0]?.["fdbc_no"]
                  : ""}
              </Text>
            </Text>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }}>
          {/* First Table */}
          <View
            style={{
              width: "100%",
            }}
          >
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
              fixed
              wrap={false}
            >
              <Text
                style={{
                  padding: "4px 3px",
                  width: "10%",
                  fontSize: "11px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                SL No.
              </Text>

              <Text
                style={{
                  padding: "4px 3px",
                  width: "40%",
                  fontSize: "11px",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Description
              </Text>
              <Text
                style={{
                  padding: "4px 3px",
                  fontSize: "11px",
                  width: "25%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Quantity (pcs)
              </Text>
              <Text
                style={{
                  padding: "4px 3px",
                  width: "25%",
                  fontWeight: "bold",
                  fontSize: "11px",
                  textAlign: "center",
                }}
              >
                Total Value ($)
              </Text>
            </View>

            <View>
              {refineTableBodyData1 &&
                refineTableBodyData1.length > 0 &&
                refineTableBodyData1?.map((item, index) => {
                  preCount = preCount + 1;
                  return (
                    <View
                      wrap={false}
                      break={index > 9}
                      key={index}
                      style={{
                        flexDirection: "row",
                        borderBottom: `${
                          (index === 9 && countPage === 1) ||
                          index + 1 === refineTableBodyData1.length
                            ? "1px solid black"
                            : 0
                        }`,
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          paddingTop: "10px",
                          fontSize: "10px",
                          width: "10%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </Text>
                      <View
                        style={{ width: "40%", borderRight: "1px solid black" }}
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            padding: "10px 3px 3px 10px",
                            fontWeight: "bold",
                          }}
                        >
                          RDL Invoice NO:{" "}
                          <Text style={{ fontSize: 10, fontWeight: "normal" }}>
                            {item?.dm_invoice_no}
                          </Text>
                        </Text>
                        <Text
                          style={{
                            fontSize: "10px",
                            padding: "1px 3px 10px 10px",
                            fontWeight: "bold",
                          }}
                        >
                          Date:{" "}
                          <Text style={{ fontSize: 10, fontWeight: "normal" }}>
                            {item?.dm_invoice_date}
                          </Text>
                        </Text>
                      </View>
                      <View
                        style={{
                          paddingTop: "5px",
                          width: "25%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <Text
                          style={{
                            paddingTop: "5px",
                            fontSize: "11px",
                            textAlign: "center",
                          }}
                        >
                          {item?.invoice_qty}
                        </Text>
                      </View>
                      <View
                        style={{
                          paddingTop: "5px",
                          width: "25%",
                          textAlign: "center",
                        }}
                      >
                        <Text
                          style={{
                            paddingTop: "5px",
                            fontSize: "11px",
                            textAlign: "center",
                          }}
                        >
                          $ {Number(item?.dm_total_value).toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              <View
                wrap={false}
                style={{
                  widthy: "100%",
                  flexDirection: "row",
                  borderRight: "1px solid black",
                  borderLeft: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                <Text
                  style={{ width: "10%", borderRight: "1px solid black" }}
                ></Text>
                <Text
                  style={{
                    width: "40%",
                    padding: "5px 8px",
                    fontSize: "11px",
                    borderRight: "1px solid black",
                    fontWeight: "bold",
                  }}
                >
                  Total RDL Invoice Quantity & Value
                </Text>
                <Text
                  style={{
                    fontSize: "10px",
                    width: "25%",
                    padding: "5px 0",
                    textAlign: "center",
                    fontWeight: "bold",
                    borderRight: "1px solid black",
                  }}
                >
                  {totalOrderQty1}
                </Text>
                <Text
                  style={{
                    width: "25%",
                    padding: 5,
                    fontSize: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  $ {totalFobValue1.toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
          {/* Second Table */}
          <View>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length > 0 &&
              tableBodyDataRefine?.map((item, index) => (
                <View
                  wrap={false}
                  break={index > 9}
                  key={index}
                  style={{
                    borderTop: `${
                      (preCount + index === 9 &&
                        refineTableBodyData1.length > 2) ||
                      (preCount + index === 8 &&
                        refineTableBodyData1.length === 1) ||
                      (preCount + index === 9 &&
                        refineTableBodyData1.length === 2) ||
                      (index === 0 && 9 <= refineTableBodyData1.length <= 10)
                        ? "1px solid black"
                        : 0
                    }`,
                    borderBottom: `${
                      (preCount + index === 8 &&
                        refineTableBodyData1.length > 2) ||
                      (preCount + index === 8 &&
                        refineTableBodyData1.length === 2) ||
                      (preCount + index === 7 &&
                        refineTableBodyData1.length === 1) ||
                      index + 1 === tableBodyDataRefine.length
                        ? "1px solid black"
                        : 0
                    }`,
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {/* {console.log("precounts", countPage)} */}
                  <Text
                    style={{
                      paddingTop: "10px",
                      fontSize: "10px",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {index + preCount}
                  </Text>
                  <View
                    style={{
                      width: "40%",
                      borderRight: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        padding: "10px 3px 3px 10px",
                        fontWeight: "bold",
                        paddingBottom: 3,
                      }}
                    >
                      Factory Invoice NO:{" "}
                      <Text style={{ fontSize: 10, fontWeight: "normal" }}>
                        {item?.invoice_no}
                      </Text>
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        padding: "1px 3px 3px 10px",
                      }}
                    >
                      Factory Name:{" "}
                      <Text style={{ fontSize: 10, fontWeight: "normal" }}>
                        {item?.factory_name}
                      </Text>
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        padding: "1px 3px 10px 10px",
                        fontWeight: "bold",
                      }}
                    >
                      Date:{" "}
                      <Text style={{ fontSize: 10, fontWeight: "normal" }}>
                        {item?.invoice_date}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      paddingTop: "5px",
                      width: "25%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    <Text
                      style={{
                        paddingTop: "5px",
                        fontSize: "11px",
                        textAlign: "center",
                      }}
                    >
                      {item?.invoice_qty}
                    </Text>
                  </View>
                  <View
                    style={{
                      paddingTop: "5px",
                      width: "25%",
                      textAlign: "center",
                    }}
                  >
                    <Text
                      style={{
                        paddingTop: "5px",
                        fontSize: "11px",
                        textAlign: "center",
                      }}
                    >
                      $ {Number(item?.invoice_value).toFixed(2)}
                    </Text>
                  </View>
                </View>
              ))}

            <View
              wrap={false}
              style={{
                width: "100%",
                flexDirection: "row",
                border: "1px solid black",
              }}
            >
              <Text
                style={{ width: "10%", borderRight: "1px solid black" }}
              ></Text>
              <Text
                style={{
                  borderRight: "1px solid black",
                  width: "40%",
                  padding: "5px 8px",
                  fontWeight: "bold",
                  fontSize: "11px",
                }}
              >
                Total Factory Invoice Quantity & Value
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  width: "25%",
                  padding: "5px 0",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                }}
              >
                {totalOrderQty2}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  width: "25%",
                  padding: "5px 0",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                $ {totalFobValue2.toFixed(2)}
              </Text>
            </View>
          </View>

          {/* grand total */}
          <View wrap={false} style={{ border: "1px solid black" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  height: "auto",
                  width: "75%",
                  borderRight: "1px solid black",
                }}
              >
                Commission
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "25%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                $ {totalCommValue.toFixed(2)}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderTop: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  height: "auto",
                  width: "100%",
                  paddingLeft: 5,
                }}
              >
                USD IN WORD:{" "}
                <Text style={{ fontSize: "9px", fontWeight: "normal" }}>
                  {totalValueInword?.replace(/dollars/g, "").toUpperCase()}{" "}
                  ONLY.
                </Text>
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderTop: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  height: "auto",
                  width: "75%",
                }}
              >
                TAKA IN WORD:{" "}
                <Text style={{ fontSize: "9px", fontWeight: "normal" }}>
                  {totalBDTValueInword?.replace(/dollars/g, "").toUpperCase()}{" "}
                  ONLY.
                </Text>
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  height: "auto",
                  width: "25%",
                }}
              >
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  BDT
                </Text>{" "}
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {BDTTaka ? BDTTaka.toFixed(2) : 0}{" "}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderTop: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  height: "auto",
                  width: "30%",
                  paddingLeft: 5,
                  borderRight: "1px solid black",
                }}
              >
                Currency Rate
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "20%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                USD
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "25%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {reportHeaderData
                  ? Number(
                      reportHeaderData?.["0"]?.["conversion_rate"]
                    ).toFixed(2)
                  : " "}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "25%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {" "}
              </Text>
            </View>
          </View>
        </View>

        {/* Commision && Percentage part */}

        <View
          wrap={false}
          style={{ marginTop: 40, border: "1px solid black", width: "366px" }}
        >
          <View
            style={{
              width: "365px",
              borderBottom: "1px solid black",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                paddingLeft: 5,
                width: "125px",
                backgroundColor: "yellow",
                borderRight: "1px solid black",
              }}
            >
              Name Of Office
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "120px",
                backgroundColor: "yellow",
                textAlign: "center",
                borderRight: "1px solid black",
              }}
            >
              Commission
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "119px",
                backgroundColor: "yellow",
                textAlign: "center",
              }}
            >
              Percentage
            </Text>
          </View>

          <View
            style={{
              width: "365px",
              borderBottom: "1px solid black",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                paddingLeft: 5,
                width: "125px",
                borderRight: "1px solid black",
              }}
            >
              Dhaka
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "120px",
                textAlign: "center",
                borderRight: "1px solid black",
              }}
            >
              {commPercentage && commPercentage?.["0"]?.["actual_value"]
                ? Number(commPercentage?.["0"]?.["actual_value"]).toFixed(2)
                : ""}
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "120px",
                textAlign: "center",
              }}
            >
              {commPercentage && commPercentage?.["0"]?.["actual_comm"]
                ? Number(commPercentage?.["0"]?.["actual_comm"]).toFixed(2)
                : ""}
            </Text>
          </View>

          <View
            style={{
              width: "365px",
              borderBottom: "1px solid black",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                paddingLeft: 5,
                width: "125px",
                borderRight: "1px solid black",
              }}
            >
              Latin Office
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "120px",
                textAlign: "center",
                borderRight: "1px solid black",
              }}
            >
              {commPercentage && commPercentage?.["0"]?.["overseas_value"]
                ? Number(commPercentage?.["0"]?.["overseas_value"]).toFixed(2)
                : ""}
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "120px",
                textAlign: "center",
              }}
            >
              {commPercentage && commPercentage?.["0"]?.["overseas_comm"]
                ? Number(commPercentage?.["0"]?.["overseas_comm"]).toFixed(2)
                : ""}
            </Text>
          </View>

          <View
            style={{
              width: "365px",
              borderBottom: "1px solid black",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                paddingLeft: 5,
                width: "125px",
                borderRight: "1px solid black",
              }}
            >
              Others
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "120px",
                textAlign: "center",
                borderRight: "1px solid black",
              }}
            >
              {commPercentage && commPercentage?.["0"]?.["other_value"]
                ? Number(commPercentage?.["0"]?.["other_value"]).toFixed(2)
                : ""}
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "120px",
                textAlign: "center",
              }}
            >
              {commPercentage && commPercentage?.["0"]?.["other_comm"]
                ? Number(commPercentage?.["0"]?.["other_comm"]).toFixed(2)
                : ""}
            </Text>
          </View>

          <View
            style={{
              width: "365px",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                paddingLeft: 5,
                width: "125px",
                fontWeight: "bold",
                borderRight: "1px solid black",
              }}
            >
              Total
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "120px",
                textAlign: "center",
                fontWeight: "bold",
                borderRight: "1px solid black",
              }}
            >
              {(
                Number(
                  commPercentage && commPercentage?.["0"]?.["other_value"]
                    ? Number(commPercentage?.["0"]?.["other_value"]).toFixed(2)
                    : 0
                ) +
                Number(
                  commPercentage && commPercentage?.["0"]?.["overseas_value"]
                    ? Number(commPercentage?.["0"]?.["overseas_value"]).toFixed(
                        2
                      )
                    : 0
                ) +
                Number(
                  commPercentage && commPercentage?.["0"]?.["actual_value"]
                    ? Number(commPercentage?.["0"]?.["actual_value"]).toFixed(2)
                    : 0
                )
              ).toFixed(2)}
            </Text>
            <Text
              style={{
                fontSize: 10,
                width: "120px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {(
                Number(
                  commPercentage && commPercentage?.["0"]?.["other_comm"]
                    ? Number(commPercentage?.["0"]?.["other_comm"]).toFixed(2)
                    : 0
                ) +
                Number(
                  commPercentage && commPercentage?.["0"]?.["overseas_comm"]
                    ? Number(commPercentage?.["0"]?.["overseas_comm"]).toFixed(
                        2
                      )
                    : 0
                ) +
                Number(
                  commPercentage && commPercentage?.["0"]?.["actual_comm"]
                    ? Number(commPercentage?.["0"]?.["actual_comm"]).toFixed(2)
                    : 0
                )
              ).toFixed(2)}
            </Text>
          </View>
        </View>

        {/* Signature part */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Concern Merchandis Checked
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Authorized Singnature
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => {
            if (pageNumber) {
              countPage = pageNumber;
            }
            return `${pageNumber} / ${totalPages}`;
          }}
          fixed
        />
        {console.log("pageNumber", countPage, "ffff")}
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Arial",
    minWidth: "100vw",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
