import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*               COUNTRIES API CALLS               */
/*-------------------------------------------------*/

export async function retrieveCountriesData() {
    return await axiosClient.get("/countries");
  }
  
  export async function addCountryDB(value) {
  
  console.log("country values", value);
  
  var bodyFormData = new FormData();
  
    bodyFormData.append("country_name", value.country_name);
    bodyFormData.append("flag", value.flag);
    bodyFormData.append("short_name", value.short_name);
    bodyFormData.append("created_by", value.created_by);
    
  
    return await axiosClient.post("/countries", bodyFormData);
  }
  
  export async function deleteCountryDB(id) {
    return await axiosClient.delete(`/countries/${id}`);
  }
  
  export async function bulkDeleteCountryDB(ids) {
    console.log("country Ids", ids);
    return await axiosClient.post(`/countries/bulk_delete`, ids);
  }
  
  export async function editCountryDB(value) {
   
    console.log("edit country data33", value);
  
    var bodyFormData = new FormData();
  
    bodyFormData.append("country_name", value.country_name);
    bodyFormData.append("flag", value.flag);
    bodyFormData.append("short_name", value.short_name);
    bodyFormData.append("created_by", value.created_by);
    bodyFormData.append("created_date", value.created_date);
    bodyFormData.append("modified_by", value.modified_by);
  
    return await axiosClient.put(`/countries/${value.id}`, bodyFormData);
  }
  