import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import AppMenuItemComponent from "./SubMenuItems";
import ModuleChildrenItem from "./Sub_SubMenuItems";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";

const ModuleItem = ({ isSidebarOpened, toggleMenu, activeRoute, ...item }) => {
  const {
    menu_caption,
    menu_link,
    parent_menu,
    menu_icon,
    id,
    children = [],
  } = item;
  const classes = useStyles();
  const isExpandable = children && children.length > 0;
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }
  console.log("menu3311111111111", children);

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={
        activeRoute === id ? classes.menuItemSelected : classes.menuItem
      }
      menu_link={menu_link}
      parent_menu={parent_menu}
      onClick={() => toggleMenu(id)}
      id={id}
    >
      {/* Display an icon if any */}
      {!!menu_icon && (
        <Tooltip
          title={menu_caption}
          placement="right"
          interactive
          disableHoverListener={isSidebarOpened}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon>{menu_icon}</Icon>
          </ListItemIcon>
        </Tooltip>
      )}
      <ListItemText
        classes={{
          primary:
            activeRoute === id
              ? classes.listItemTextOpen
              : activeRoute !== id
              ? classes.ListItemText
              : classes.listItemTextClose,
        }}
        primary={menu_caption}
        inset={!menu_icon}
      />
      {/* Display the expand menu if the item has children */}
      {isExpandable && activeRoute !== id && (
        <IconExpandMore style={{ fontSize: 14 }} />
      )}
      {isExpandable && activeRoute === id && (
        <IconExpandLess style={{ fontSize: 14 }} />
      )}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      in={activeRoute === id ? true : false}
      timeout="auto"
      unmountOnExit
      style={{ backgroundColor: "#818a8c" }}
    >
      <Divider />
      <List component="div" disablePadding>
        {children
          .sort((a, b) => a.psl - b.psl)
          .map((item, index) => (
            <div>
              <ModuleChildrenItem
                {...item}
                key={index}
                activeRoute={activeRoute}
                isSidebarOpened={isSidebarOpened}
                toggleMenu={toggleMenu}
              />
            </div>
          ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {(isExpandable && MenuItemRoot) || (menu_link !== "/" && MenuItemRoot)}
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      "&.active": {
        background: theme.palette.primary.main,
        "& .MuiListItemIcon-root": {
          color: "white",
        },
        "& .MuiListItemText-root": {
          color: "#FFFFFF",
        },
      },

      height: 35,
    },
    menuItemSelected: {
      "&.active": {
        background: "#3D8361",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
        "& .MuiListItemText-root": {
          color: "#FFFFFF",
        },
      },
      // borderRadius: 5,
      // borderLeft: "3px solid white",
      backgroundColor: "#818a8c",
      height: 35,
    },
    menuItemIcon: {
      color: "#F92789",
      marginLeft: -2,
    },
    ListItemText: {
      fontSize: 12,
      color: "white",
      marginLeft: 10,
    },
    listItemTextOpen: {
      fontSize: 12,
      color: "white",
      marginLeft: 10,
    },
    listItemTextClose: {
      fontSize: 12,
      color: "white",
      marginLeft: 10,
    },
  })
);

export default ModuleItem;
