import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AppProvider from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import "./index.css";
import {
  Signin
} from "./pages";
import CommissionDoc from "./pages/Reports/CommissionDistribution";
import ExportSummaryLCDoc from "./pages/Reports/ExportSumLCWise(Report)";
import ExportSummarySCDoc from "./pages/Reports/ExportSumSCWise(Report)";
import FOrderDoc from "./pages/Reports/FOrders";
import LCTSummaryDoc from "./pages/Reports/LcTransferSummary/Invoice";
import OrderDoc from "./pages/Reports/Orders";
import SCDoc from "./pages/Reports/SalesContract";
import SCCDoc from "./pages/Reports/salesContractCommission";
import ExportReportAll from "./pages/modules/Documents/Export/ReportAllBuyer";
import ExportReport from "./pages/modules/Documents/Export/Reports";

import ExportReportMonth from "./pages/modules/Documents/Export/ReportMonth";
import ExportReportMonthAll from "./pages/modules/Documents/Export/ReportMonthAllBuyer";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import CommissionInvoice from "./pages/Reports/CommissionInvoice";
import CostingReport from "./pages/Reports/CostingReport";
import DebitNoteDoc from "./pages/Reports/DebitNote";
import LcScClosing from "./pages/Reports/LcSc_Closing";
import FactoryInvoice from "./pages/Reports/factoryInvoice";
import RdlInvoice from "./pages/Reports/rdlInvoice";
import DocSubDONEPaymentRecvNOT from "./pages/modules/Documents/TNABasedCommercial/Reports/docSubDONEptRecNOT";
import EtdDONEdocSubNOT from "./pages/modules/Documents/TNABasedCommercial/Reports/etdDONEdocSubNOT";
import ExfDONEetdNOT from "./pages/modules/Documents/TNABasedCommercial/Reports/exfDONEetdNOT";
import PaymentDONECommissionNOT from "./pages/modules/Documents/TNABasedCommercial/Reports/ptDONEcomissionNOT";
import PaymentRecvDONEFddNOT from "./pages/modules/Documents/TNABasedCommercial/Reports/ptRecDONEfddNOT";
import ETDSalesReportMonth from "./pages/modules/Documents/etd/ReportMonth";
import ETDSalesReport from "./pages/modules/Documents/etd/Reports";
import ExFactorySalesReportMonth from "./pages/modules/Documents/ex-factory/ReportMonth";
import ExFactorySalesReport from "./pages/modules/Documents/ex-factory/Reports";
import HandoverSalesReportMonth from "./pages/modules/Documents/handOver/ReportMonth";
import HandoverSalesReport from "./pages/modules/Documents/handOver/Reports";
import store from "./redux/store";
import registerServiceWorker from "./registerServiceWorker";
import PrivateRoute from "./utils/router/PrivateRoute";

render(
  <Provider store={store}>
    <AppProvider>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/404" component={NotFound} />
          <Route exact path="/500" component={BackendError} /> */}
          {/* <Route exact path="/Lockscreen" component={Lockscreen} /> */}
          <Route exact path="/signin" component={Signin} />
          <PrivateRoute path="/app" component={Dashboard} />
          <Redirect exact from="/" to="/signin" />
          <Route exact path="/doc/order/:id" component={OrderDoc} />
          <Route
            exact
            path="/doc/export_summary_lc"
            component={ExportSummaryLCDoc}
          />
          <Route
            exact
            path="/doc/costing_report"
            component={CostingReport}
          />

          <Route
            exact
            path="/doc/export_summary_sc"
            component={ExportSummarySCDoc}
          />
          <Route exact path="/doc/factory_order/:id" component={FOrderDoc} />
          <Route exact path="/doc/sales_contract/:id" component={SCDoc} />
          <Route
            exact
            path="/doc/sales_contract_commission/:id"
            component={SCCDoc}
          />
          <Route
            exact
            path="/doc/lc_transfer_summary"
            component={LCTSummaryDoc}
          />
          <Route exact path="/doc/commissions/:id" component={CommissionDoc} />
          <Route exact path="/doc/export_report" component={ExportReport} />
          <Route
            exact
            path="/doc/export_report/all_buyer"
            component={ExportReportAll}
          />
          <Route
            exact
            path="/doc/export_report/:id"
            component={ExportReportMonth}
          />
          <Route
            exact
            path="/doc/export_report/:id/all_buyer"
            component={ExportReportMonthAll}
          />
          <Route
            exact
            path="/doc/ex_factory_datewise_sales"
            component={ExFactorySalesReport}
          />
          <Route
            exact
            path="/doc/ex_factory_datewise_sales/:id"
            component={ExFactorySalesReportMonth}
          />
          <Route
            exact
            path="/doc/handover_datewise_sales"
            component={HandoverSalesReport}
          />
          <Route
            exact
            path="/doc/etd_datewise_sales"
            component={ETDSalesReport}
          />
          <Route exact path="/doc/lc_sc_closing" component={LcScClosing} />
          <Route
            exact
            path="/doc/handover_datewise_sales/:id"
            component={HandoverSalesReportMonth}
          />
          <Route
            exact
            path="/doc/etd_datewise_sales/:id"
            component={ETDSalesReportMonth}
          />
          <Route
            exact
            path="/doc/factory_invoice/:id"
            component={FactoryInvoice}
          />
          <Route exact path="/doc/rdl_invoice/:id" component={RdlInvoice} />
          <Route
            exact
            path="/doc/commission_invoice/:id"
            component={CommissionInvoice}
          />
          <Route
            exact
            path="/doc/debit_note/:slug/:id"
            component={DebitNoteDoc}
          />
          <Route
            exact
            path="/doc/tna_based_commercial_report/exf_done_etd_not/:id"
            component={ExfDONEetdNOT}
          />
          <Route
            exact
            path="/doc/tna_based_commercial_report/etd_done_doc_submit_not/:id"
            component={EtdDONEdocSubNOT}
          />
          <Route
            exact
            path="/doc/tna_based_commercial_report/doc_submit_done_payment_recv_not/:id"
            component={DocSubDONEPaymentRecvNOT}
          />
          <Route
            exact
            path="/doc/tna_based_commercial_report/payment_recv_done_fdd_not/:id"
            component={PaymentRecvDONEFddNOT}
          />
          <Route
            exact
            path="/doc/tna_based_commercial_report/payment_done_commission_not/:id"
            component={PaymentDONECommissionNOT}
          />
        </Switch>
      </BrowserRouter>
      <ToastContainer style={{width: 350}}/>
    </AppProvider>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
