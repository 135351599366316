import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";

// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const reportHeaderData =
    invoiceData && invoiceData.length ? invoiceData[0]?.["0"] : {};
  const tableBodyData = invoiceData && invoiceData.length ? invoiceData[0] : {};
  const currencyName = invoiceData && invoiceData.length ? invoiceData[1] : {};

  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : [];

  const totalOrderQty =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) => totalValue + Number(currentValue?.qty),
          0
        )
      : 0;

  // totalRDLValue
  const totalRDLValue =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.rdl_value),
          0
        )
      : 0;

  // totalFactValue
  const totalFactValue =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.factory_value),
          0
        )
      : 0;

  // totalCommission
  const totalCommission =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.comm_value),
          0
        )
      : 0;
  // totalCommission
  const totalOtherCommission =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.oth_comm),
          0
        )
      : 0;
  // totalCommission
  const totalOverCommission =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.ovr_comm),
          0
        )
      : 0;

  const totalActualCommission =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.actual_comm),
          0
        )
      : 0;

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Image
            style={{
              width: "700px",
              height: "75px",
              textAlign: "center",
              margin: "auto",
            }}
            src={logo}
          />
          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                backgroundColor: "rgb(56, 56, 141)",
                width: "250px",
                color: "white",
                padding: "6px",
              }}
            >
              Commission Distribution
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-60px",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: "9px" }}>
              Print Date :
              {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
            </Text>
            <Text style={{ fontSize: "9px" }}>
              Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
            </Text>
          </View>
        </View>

        {/* Header Info */}
        <View
          style={{
            marginTop: "20px",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              marginLeft: `${
                reportHeaderData && reportHeaderData?.buyer_name ? 87 : 108
              }`,
            }}
          >
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 3 }}
            >
              Buyer Name
            </Text>
          </View>
          <View
            style={{
              marginLeft: `${
                reportHeaderData && reportHeaderData?.buyer_name ? 157 : 196
              }`,
            }}
          >
            <Text style={{ fontSize: "11px", paddingBottom: 3 }}>
              :{" "}
              {reportHeaderData && reportHeaderData?.buyer_name
                ? reportHeaderData?.buyer_name
                : ""}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginBottom: "20px",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 2 }}
            >
              Order No
            </Text>
          </View>

          <View>
            <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
              : {reportHeaderData && reportHeaderData?.dm_order_no}
            </Text>
          </View>

          <View>
            <Text
              style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 2 }}
            >
              Order Date
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
              :{" "}
              {new Date(
                reportHeaderData ? reportHeaderData?.order_date : null
              ).toLocaleDateString(["es-CL", "id"])}
            </Text>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            wrap={false}
            fixed
          >
            <Text
              style={{
                padding: "4px 3px",
                width: "10%",
                fontSize: "11px",
                fontWeight: "bold",
                paddingTop: 10,
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Style
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "10%",
                fontSize: "11px",
                fontWeight: "bold",
                paddingTop: 10,
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Buyer PO
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "10%",
                paddingTop: 10,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Order Qty
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "10%",
                paddingTop: 10,
                fontWeight: "bold",
                borderRight: "1px solid black",
                fontSize: "11px",
                textAlign: "center",
              }}
            >
              RDL Fob
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "10%",
                paddingTop: 10,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              RDL Value
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "10%",
                paddingTop: 10,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Factory Fob
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "10%",
                paddingTop: 10,
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Factory Value
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "10%",
                paddingTop: 10,
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Commission (%)
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "10%",
                paddingTop: 10,
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Commission
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "10%",
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Oversease {"\n"} Commission
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "10%",
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Other {"\n"} Commission
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "10%",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Actual {"\n"} Commission
            </Text>
          </View>
          <View>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length &&
              tableBodyDataRefine.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.style ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.buyer_po ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.qty ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["primary_currency_symbol"]
                      ? currencyName?.["0"]?.["primary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.rdl_fob).toFixed(2) ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["primary_currency_symbol"]
                      ? currencyName?.["0"]?.["primary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.rdl_value).toFixed(2) ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["primary_currency_symbol"]
                      ? currencyName?.["0"]?.["primary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.factory_fob).toFixed(2) ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["primary_currency_symbol"]
                      ? currencyName?.["0"]?.["primary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.factory_value).toFixed(2) ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.comm_per).toFixed(2) ?? 0} %
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["primary_currency_symbol"]
                      ? currencyName?.["0"]?.["primary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.comm_value).toFixed(2) ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["primary_currency_symbol"]
                      ? currencyName?.["0"]?.["primary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.ovr_comm).toFixed(2) ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["primary_currency_symbol"]
                      ? currencyName?.["0"]?.["primary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.oth_comm).toFixed(2) ?? 0}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",

                      textAlign: "center",
                    }}
                  >
                    {currencyName &&
                    currencyName?.["0"]?.["primary_currency_symbol"]
                      ? currencyName?.["0"]?.["primary_currency_symbol"]
                      : " "}{" "}
                    {Number(item?.actual_comm).toFixed(2) ?? 0}
                  </Text>
                </View>
              ))}
            <View
              wrap={false}
              style={{
                flexDirection: "row",
                width: "100%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalOrderQty}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["primary_currency_symbol"]
                  ? currencyName?.["0"]?.["primary_currency_symbol"]
                  : " "}{" "}
                {totalRDLValue.toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["primary_currency_symbol"]
                  ? currencyName?.["0"]?.["primary_currency_symbol"]
                  : " "}{" "}
                {totalFactValue.toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalPerCommission.toFixed(2)} % */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["primary_currency_symbol"]
                  ? currencyName?.["0"]?.["primary_currency_symbol"]
                  : " "}{" "}
                {totalCommission.toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["primary_currency_symbol"]
                  ? currencyName?.["0"]?.["primary_currency_symbol"]
                  : " "}{" "}
                {totalOverCommission.toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["primary_currency_symbol"]
                  ? currencyName?.["0"]?.["primary_currency_symbol"]
                  : " "}{" "}
                {totalOtherCommission.toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "9px",
                  height: "auto",
                  fontWeight: "bold",
                  width: "10%",
                  textAlign: "center",
                }}
              >
                {currencyName &&
                currencyName?.["0"]?.["primary_currency_symbol"]
                  ? currencyName?.["0"]?.["primary_currency_symbol"]
                  : " "}{" "}
                {totalActualCommission.toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        {/* Signature part */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",

  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Arial",
    minWidth: "120vw",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
