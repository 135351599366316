import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";

const fakeData = [
  {
    buyer_name: "RIPLEY S.A",
    factory_name: "REAZ KNITWEAR LTD",
    lc_no: "821013400001817",
    lc_open_date: "2022-12-14",
    lc_transfer_date: "2023-05-07",
    lc_value: "142159.06",
    quantity: null,
    sales_contract_no: "RDL/SC/RIPLEY/REA/22/001",
    transfer_qty: 6000,
    transfer_value: "81560.0000",
  },
];

// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const reportHeaderData = invoiceData && invoiceData.length && invoiceData[0];
  const tableBodyData = invoiceData && invoiceData.length && invoiceData?.[0];
  const tableBodyDataRefine = [];
  // const tableBodyDataRefine =
  //   tableBodyData &&
  //   Object.keys(tableBodyData).map((key) => tableBodyData[key]);

  // const totalOrderQty =
  //   tableBodyDataRefine && tableBodyDataRefine.length &&
  //   tableBodyDataRefine.reduce(
  //     (totalValue, currentValue) => totalValue + Number(currentValue?.lot_qty),
  //     0
  //   );
  // totalFobValue
  // const totalFobValue =
  //   tableBodyDataRefine && tableBodyDataRefine.length &&
  //   tableBodyDataRefine.reduce(
  //     (totalValue, currentValue) => totalValue + Number(currentValue?.dm_value),
  //     0
  //   );

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginTop: -15,
            marginBottom: "20px",
          }}
        >
          <Image
            style={{
              width: "700px",
              height: "75px",
              textAlign: "center",
              margin: "auto",
            }}
            src={logo}
          />
          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                // backgroundColor: "rgb(56, 56, 141)",
                width: "200px",
                // color: "white",
                padding: "6px",
              }}
            >
              LC Transfer Summary
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-60px",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: "9px" }}>Print Date : {defaultValue}</Text>
            <Text style={{ fontSize: "9px" }}>
              Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
            </Text>
          </View>
        </View>

        {/* Header Info */}
        <View>
          <View
            style={{
              height: "70px",
              marginTop: "20px",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <View style={{}}>
              <Text
                style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 2 }}
              >
                Buyer Name
              </Text>

              <Text
                style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 2 }}
              >
                Total Qty
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                : {reportHeaderData && reportHeaderData?.["buyer_name"]}
              </Text>
              <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                : {reportHeaderData && reportHeaderData?.["transfer_qty"]}
              </Text>
            </View>
            <View>
              <Text
                style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 2 }}
              >
                Master L/C No
              </Text>
              <Text
                style={{ fontSize: "11px", fontWeight: 900, paddingBottom: 2 }}
              >
                Master L/C Value
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                : {reportHeaderData && reportHeaderData?.["lc_no"]}
              </Text>
              <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                : {reportHeaderData && reportHeaderData?.["lc_value"]}
              </Text>
            </View>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "4px 3px",
                width: "8%",
                fontSize: "11px",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              SL
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "23%",
                fontSize: "11px",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Factory Name
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "23%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Factory Transfer Value
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "23%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                fontSize: "11px",
                textAlign: "center",
              }}
            >
              Factory Transfer Qty
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "11px",
                width: "23%",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Transfer Date
            </Text>
          </View>
          <View style={{ borderTop: "1px solid black" }}>
            {invoiceData &&
              invoiceData.length &&
              invoiceData?.map((item, index) => (
                <View
                  key={index}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "8%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "23%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.factory_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "23%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.transfer_value).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "23%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.transfer_qty}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "23%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.lc_transfer_date}
                  </Text>
                </View>
              ))}
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  height: "auto",
                  width: "248px",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Total
              </Text>

              <Text
                style={{
                  paddingTop: "5px",
                  fontSize: "10px",
                  height: "20px",
                  width: "23%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalOrderQty} */}
              </Text>
              <Text
                style={{
                  paddingTop: "5px",
                  fontSize: "8px",
                  height: "20px",
                  width: "23%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
              <Text
                style={{
                  paddingTop: "5px",
                  fontSize: "8px",
                  height: "20px",
                  width: "23%",
                  textAlign: "center",
                }}
              >
                {/* $ {totalFobValue} */}
              </Text>
            </View>
          </View>
        </View>

        {/* Signature part */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Arial",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
