import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";

// Create styles
const Quixote = ({ invoiceData, buyer, getInfo }) => {
  const tableBodyData = invoiceData ? invoiceData : {};

  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : [];

  const totalInvoiceValue =
    tableBodyDataRefine && tableBodyDataRefine.length
      ? tableBodyDataRefine.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.invoice_value),
          0
        )
      : 0;

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        {/* <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Text style={{fontSize: "16px", fontWeight: 900}}>Payment Received But Not Yet FDD To Factory</Text>
          <Text style={{fontSize: "12px"}}>Renaissance Designs Ltd</Text>
          <Text style={{fontSize: "12px"}}>
          House 14, Road 2, Sector 3, Uttara, Dhaka, Bangladesh
          </Text>          
        </View> */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Image
            style={{
              width: "700px",
              height: "75px",
              textAlign: "center",
              margin: "auto",
            }}
            src={logo}
          />
          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                // backgroundColor: "rgb(56, 56, 141)",
                // width: "250px",
                // color: "white",
                padding: "6px",
              }}
            >
              Payment Received But Not Yet FDD To Factory
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "-60px",
            marginBottom: "20px",
          }}
        >
          <View>
            <Text style={{ fontSize: "9px" }}>Print Date : {defaultValue}</Text>
            <Text style={{ fontSize: "9px" }}>
              Print by : {getInfo?.first_name + ` ` + getInfo?.last_name}
            </Text>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }}>
          <View
            style={{
              border: "1px solid black",
              borderBottom: 0,
              width: "400px",
              flexDirection: "row",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            <Text style={{ fontSize: "11px", fontWeight: "bold", padding: 5 }}>
              Buyer Name :{buyer}
            </Text>
          </View>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "5px",
                width: "10%",
                fontSize: "11px",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              SL
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "11px",
                width: "15%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Proceed Date
            </Text>
            <Text
              style={{
                padding: "5px",
                width: "15%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                fontSize: "11px",
                textAlign: "center",
              }}
            >
              LC/SC NO.
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "11px",
                width: "15%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              RDL FDBC/TT NO.
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "11px",
                width: "15%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Factory Invoice Number
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "11px",
                width: "15%",
                borderRight: "1px solid black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Factory Invoice Value
            </Text>
            <Text
              style={{
                padding: "5px",
                fontSize: "11px",
                width: "15%",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Factory Name
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: "11px",
                padding: 5,
                fontWeight: "bold",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
              }}
            >
              Buyer Name :{buyer}
            </Text>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length &&
              tableBodyDataRefine.map((item, index) => (
                <View
                  break={index > 3}
                  wrap={false}
                  key={index}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    border: "1px solid black",
                  }}
                >
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </Text>

                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.rezli_date}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.lcno}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.fdbc_no} {item?.tt_no}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.invoice_no}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    $ {item?.invoice_value}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "10px",
                      height: "auto",
                      width: "15%",
                      textAlign: "center",
                    }}
                  >
                    {item?.factory_name}
                  </Text>
                </View>
              ))}
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  height: "auto",
                  width: "10%",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Total
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "15%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "15%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "15%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "15%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "15%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                $ {totalInvoiceValue.toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "15%",
                  textAlign: "center",
                }}
              >
                {""}
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",

  fonts: [
    {
      src: "/asserts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/asserts/ARIALN.TTF",
    },
    {
      src: "/asserts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Arial",
    minWidth: "120vw",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
