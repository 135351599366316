import {
  ADD_STYLES,
  ADD_STYLES_ERROR,
  ADD_STYLES_OK,
  BEGIN_EDIT_STYLES,
  BEGIN_STYLES_DOWNLOAD,
  RETRIEVE_STYLES_BULK_DELETE,
  RETRIEVE_STYLES_DELETE,
  RETRIEVE_STYLES_EDIT,
  STYLES_BULK_DELETED_ERROR,
  STYLES_BULK_DELETED_OK,
  STYLES_DELETED_ERROR,
  STYLES_DELETED_OK,
  STYLES_DOWNLOAD_ERROR,
  STYLES_DOWNLOAD_OK,
  STYLES_EDITED_ERROR,
  STYLES_EDITED_OK,
} from "../../constants/modules/stylesConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteStyleAction = (item) => ({
  type: RETRIEVE_STYLES_BULK_DELETE,
  payload: true,
  style: item,
});

export const bulkDeleteStyleOkAction = () => ({
  type: STYLES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteStyleErrorAction = (state) => ({
  type: STYLES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadStylesAction = () => ({
  type: BEGIN_STYLES_DOWNLOAD,
  payload: true,
});

export const downloadStylesOkAction = (items) => ({
  type: STYLES_DOWNLOAD_OK,
  payload: items,
});

export const downloadStylesErrorAction = () => ({
  type: STYLES_DOWNLOAD_ERROR,
  payload: true,
});

export const addStyleAction = (item) => ({
  type: ADD_STYLES,
  payload: true,
  style: item,
});

export const addStyleOkAction = () => ({
  type: ADD_STYLES_OK,
  payload: true,
});

export const addStyleErrorAction = (state) => ({
  type: ADD_STYLES_ERROR,
  payload: state,
});

export const deleteStyleAction = (id) => ({
  type: RETRIEVE_STYLES_DELETE,
  payload: id,
});

export const deleteStyleOkAction = () => ({
  type: STYLES_DELETED_OK,
});

export const deleteStyleErrorAction = () => ({
  type: STYLES_DELETED_ERROR,
  payload: true,
});

export const retrieveStyleAction = (item) => ({
  type: RETRIEVE_STYLES_EDIT,
  payload: item,
});

export const editStyleAction = (item) => ({
  type: BEGIN_EDIT_STYLES,
  style: item,
});

export const editStyleOkAction = (item) => ({
  type: STYLES_EDITED_OK,
  payload: item,
});

export const editStyleErrorAction = () => ({
  type: STYLES_EDITED_ERROR,
  payload: true,
});
