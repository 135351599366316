import {
  ADD_COSTING_ITEMS,
  ADD_COSTING_ITEMS_ERROR,
  ADD_COSTING_ITEMS_OK,
  BEGIN_EDIT_COSTING_ITEMS,
  BEGIN_COSTING_ITEMS_DOWNLOAD,
  RETRIEVE_COSTING_ITEMS_BULK_DELETE,
  RETRIEVE_COSTING_ITEMS_DELETE,
  RETRIEVE_COSTING_ITEMS_EDIT,
  COSTING_ITEMS_BULK_DELETED_ERROR,
  COSTING_ITEMS_BULK_DELETED_OK,
  COSTING_ITEMS_DELETED_ERROR,
  COSTING_ITEMS_DELETED_OK,
  COSTING_ITEMS_DOWNLOAD_ERROR,
  COSTING_ITEMS_DOWNLOAD_OK,
  COSTING_ITEMS_EDITED_ERROR,
  COSTING_ITEMS_EDITED_OK,
} from "../../constants/modules/costingItemsConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteCostingItemAction = (item) => ({
  type: RETRIEVE_COSTING_ITEMS_BULK_DELETE,
  payload: true,
  costing_item: item,
});

export const bulkDeleteCostingItemOkAction = () => ({
  type: COSTING_ITEMS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteCostingItemErrorAction = (state) => ({
  type: COSTING_ITEMS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadCostingItemsAction = () => ({
  type: BEGIN_COSTING_ITEMS_DOWNLOAD,
  payload: true,
});

export const downloadCostingItemsOkAction = (items) => ({
  type: COSTING_ITEMS_DOWNLOAD_OK,
  payload: items,
});

export const downloadCostingItemsErrorAction = () => ({
  type: COSTING_ITEMS_DOWNLOAD_ERROR,
  payload: true,
});

export const addCostingItemAction = (item) => ({
  type: ADD_COSTING_ITEMS,
  payload: true,
  costing_item: item,
});

export const addCostingItemOkAction = () => ({
  type: ADD_COSTING_ITEMS_OK,
  payload: true,
});

export const addCostingItemErrorAction = (state) => ({
  type: ADD_COSTING_ITEMS_ERROR,
  payload: state,
});

export const deleteCostingItemAction = (id) => ({
  type: RETRIEVE_COSTING_ITEMS_DELETE,
  payload: id,
});

export const deleteCostingItemOkAction = () => ({
  type: COSTING_ITEMS_DELETED_OK,
});

export const deleteCostingItemErrorAction = () => ({
  type: COSTING_ITEMS_DELETED_ERROR,
  payload: true,
});

export const retrieveCostingItemAction = (item) => ({
  type: RETRIEVE_COSTING_ITEMS_EDIT,
  payload: item,
});

export const editCostingItemAction = (item) => ({
  type: BEGIN_EDIT_COSTING_ITEMS,
  costing_item: item,
});

export const editCostingItemOkAction = (item) => ({
  type: COSTING_ITEMS_EDITED_OK,
  payload: item,
});

export const editCostingItemErrorAction = () => ({
  type: COSTING_ITEMS_EDITED_ERROR,
  payload: true,
});
