import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COSTING_ITEMS,
  BEGIN_EDIT_COSTING_ITEMS,
  BEGIN_COSTING_ITEMS_DOWNLOAD,
  RETRIEVE_COSTING_ITEMS_BULK_DELETE,
  RETRIEVE_COSTING_ITEMS_DELETE,
} from "../../constants/modules/costingItemsConstants";

import {
  addCostingItemErrorAction,
  addCostingItemOkAction,
  bulkDeleteCostingItemErrorAction,
  bulkDeleteCostingItemOkAction,
  deleteCostingItemErrorAction,
  deleteCostingItemOkAction,
  downloadCostingItemsErrorAction,
  downloadCostingItemsOkAction,
  editCostingItemErrorAction,
  editCostingItemOkAction,
} from "../../actions/modules/costingItemsActions";

import {
  addCostingItemDB,
  bulkDeleteCostingItemDB,
  deleteCostingItemDB,
  editCostingItemDB,
  retrieveCostingItemsData,
} from "../../../config/apiCalls/costingItems";

// Retrieve products
// worker saga
export function* retrieveCostingItems() {
  try {
    const response = yield call(retrieveCostingItemsData);

    if (response.status === 200) {
      yield put(downloadCostingItemsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadCostingItemsErrorAction());
  }
}

// Create new product
// worker saga
export function* addCostingItem(action) {
  const costing_item = action.costing_item;

  try {
    const response = yield call(addCostingItemDB, costing_item);

    if (response.status === 201) {
      yield delay(500);

      yield put(addCostingItemOkAction(costing_item));
      toast.success("costing_item created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addCostingItemErrorAction(true));
    toast.error("CostingItem created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteCostingItem(action) {
  const id = action.payload;
  try {
    yield call(deleteCostingItemDB, id);
    yield deleteCostingItemOkAction();
    toast.success("CostingItem deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteCostingItemErrorAction();
  }
}

// Edit product
// worker saga
export function* editCostingItem(action) {
  const costing_item = action.costing_item;

  try {
    yield call(editCostingItemDB, costing_item);
    yield editCostingItemOkAction(costing_item);
    // Alert
    toast.success("CostingItem has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editCostingItemErrorAction();
  }
}

// BULK SAGAS
export function* bulkCostingItemDelete(action) {
  const costing_item = action.costing_item;

  try {
    const response = yield call(bulkDeleteCostingItemDB, costing_item);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteCostingItemOkAction(costing_item));
      toast.success("Bulk CostingItem deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteCostingItemErrorAction(true));
    toast.error("Bulk CostingItem deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchCostingItemsAsync() {
  yield takeLatest(BEGIN_COSTING_ITEMS_DOWNLOAD, retrieveCostingItems);
  yield takeLatest(ADD_COSTING_ITEMS, addCostingItem);
  yield takeLatest(RETRIEVE_COSTING_ITEMS_DELETE, deleteCostingItem);
  yield takeLatest(BEGIN_EDIT_COSTING_ITEMS, editCostingItem);
  yield takeLatest(RETRIEVE_COSTING_ITEMS_BULK_DELETE, bulkCostingItemDelete);
}
