import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import MyDocument from "./MyDocument";

const Invoice = () => {
  React.useEffect(() => {
    document.title = "LC Transfer Summary";
  }, []);

  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const lcTransferData = localStorage.getItem("lc_transfer_summary")
    ? JSON.parse(localStorage.getItem("lc_transfer_summary"))
    : null;

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={
          lcTransferData && lcTransferData.length ? lcTransferData : null
        }
        getInfo={getInfo}
      />
    </PDFViewer>
  );
};

export default Invoice;
