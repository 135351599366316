import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

const Quixote = ({ invoiceData, companyInfo, getDOLedgerInfo }) => {
  const headerInfo = invoiceData && invoiceData?.length && invoiceData[0];
  const tableBodyData = invoiceData && invoiceData.length && invoiceData[1];


  const tableBodyDataRefine =
    tableBodyData &&
    Object.keys(tableBodyData).map((key) => tableBodyData[key]);

  const tableBodyDataRefine2 =
    invoiceData && invoiceData?.length ? 
    Object.keys(invoiceData[2]).map((key) => invoiceData[2][key]) : [];

  const tableBodyDataRefine3 =
    invoiceData && invoiceData?.length ? 
    Object.keys(invoiceData[3]).map((key) => invoiceData[3][key]) : [];

  const tableBodyDataRefine4 =
    invoiceData && invoiceData?.length ? 
    Object.keys(invoiceData[4]).map((key) => invoiceData[4][key]) : [];


  // totalAmount
  const totalAmount =
    tableBodyDataRefine &&
    tableBodyDataRefine?.length &&
    tableBodyDataRefine?.reduce(
      (totalValue, currentValue) => totalValue + Number(currentValue?.amount),
      0
    );

  // totalAmount2
  const totalAmount2 = (Number(totalAmount) + ((tableBodyDataRefine2?.[0]?.["item_per"] * totalAmount) / 100 ))?.toFixed(2);


  // totalAmount3
  const totalAmount3 = (Number(totalAmount2) + ((tableBodyDataRefine3?.[0]?.["item_per"] * totalAmount2) / 100 ))?.toFixed(2);

  const totalAmount4 = ((Number(totalAmount2) + ((tableBodyDataRefine3?.[0]?.["item_per"] * totalAmount2) / 100 )) / 12)?.toFixed(2);

// totalAmount5
  const totalAmount5 =  (Number(totalAmount4) + Number(tableBodyDataRefine4?.[0]?.["amount"]))?.toFixed(2);


  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}

        <View wrap={false}>
          <View
            style={{
              borderBottom: "3px solid black",
              paddingBottom: 5,
            }}
          >
            <View style={{ margin: "auto" }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 900,
                  fontFamily: "Arial"
                }}
              >
                {/* {companyInfo?.[0]?.["company_name"]} */}
                SUXES TEXTIL IND. LTD
              </Text>
            </View>
            <View style={{ margin: "auto" }}>
              <Text style={{ fontSize: 12 }}>
                House #2, Road #8/A, Nikunja 1, Khilkhet, Dhaka-1229
                {/* {companyInfo?.[0]?.["street"]}
                {", "}
                {companyInfo?.[0]?.["city"]}
                {", "}
                {companyInfo?.[0]?.["country"]} */}
              </Text>
            </View>
          </View>

          <View
            style={{
              height: "auto",
              marginTop: "2px",
            }}
          >
            <Text
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "Arial"
              }}
            >
              COSTING
            </Text>
            <Text
              style={{
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto",
                borderBottom: "1px solid black",
                width: 60,
              }}
            >
              {""}
            </Text>
            {/* <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                From
              </Text>{" "}
              {getDOLedgerInfo && getDOLedgerInfo?.["p_from_date"]}
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                To
              </Text>{" "}
              {getDOLedgerInfo && getDOLedgerInfo?.["p_to_date"]}
            </Text> */}
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <View>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900 }}>Print Date:</Text>{" "}
                {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
              </Text>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900 }}>Print By:</Text>{" "}
              </Text>
            </View>
          </View>
          {/* Header Info */}
          <View
            style={{
              height: "30px",
              marginTop: "20px",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <View style={{}}>
              <Text
                style={{ fontSize: "10px", fontWeight: 900, paddingBottom: 2 }}
              >
                Buyer Name
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
                : {headerInfo ? headerInfo?.["0"]?.["buyer_name"] : null}
              </Text>
            </View>
            <View>
              <Text
                style={{ fontSize: "10px", fontWeight: 900, paddingBottom: 2 }}
              >
                Style Name
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
                : {headerInfo ? headerInfo?.["0"]?.["style_name"] : null}
              </Text>
            </View>
          </View>
        </View>

        {/* TABLE PART */}
        <View>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            wrap={false}
            fixed
          >
            
            <Text
              style={{
                padding: 5,
                fontSize: "9px",
                width: "20%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Group Name
            </Text>
            <Text
              style={{
                padding: 5,
                width: "20%",
                fontSize: "9px",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Item Name
            </Text>
            
            <Text
              style={{
                paddingTop: 5,
                fontSize: "9px",
                width: "12%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              GSM/WEIGHT
            </Text>
            <Text
              style={{
                paddingTop: 5,
                fontSize: "9px",
                width: "12%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Consumption
            </Text>
            <Text
              style={{
                padding: 5,
                width: "12%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                fontSize: "9px",
                textAlign: "center",
              }}
            >
              KG/YD
            </Text>
            <Text
              style={{
                padding: 5,
                fontSize: "9px",
                width: "12%",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Price
            </Text>
            <Text
              style={{
                padding: 5,
                fontSize: "9px",
                width: "12%",
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              Total
            </Text>
          </View>
          {/* first Array */}
          <View>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length &&
              tableBodyDataRefine.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  break={index > 8}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                 
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.group_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.costing_item}
                  </Text>
                 
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.description}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.consumption}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      borderRight: "1px solid black",
                      width: "12%",
                      textAlign: "center",
                    }}
                  >
                    {item?.uom}
                  </Text>
                  <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>{Number(item?.rate).toFixed(2)}</Text>
                  </View>
                  <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>{Number(item?.amount).toFixed(2)}</Text>
                  </View>
                  
                </View>
              ))}
              <View
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
                borderTop: 0,
              }}
              wrap={false}
            >
                            
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  height: "auto",
                  width: "20%",
                  borderRight: "1px solid black",
                  textAlign: "left",
                }}
              >
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "20%",
                  // color: "green",
                  // fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                TOTAL FOB NET
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                 
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                 
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
              </Text>
              
                  <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>{Number(totalAmount).toFixed(2)}</Text>
                  </View>
             
            </View>
           
          </View>
          {/* second Array */}
          <View>
            {tableBodyDataRefine2 &&
              tableBodyDataRefine2.length &&
              tableBodyDataRefine2.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  break={index > 8}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.group_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.costing_item}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {item?.description} */}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {item?.consumption} */}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      borderRight: "1px solid black",
                      width: "12%",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.item_per).toFixed(2)} %
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {Number(item?.rate).toFixed(2)} */}
                  </Text>
                 
                  <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>
                        {((Number(item?.item_per) * Number(totalAmount)) / 100).toFixed(2)}
                      </Text>
                  </View>


                
                </View>
              ))}
              <View
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
                borderTop: 0,
              }}
              wrap={false}
            >
              
              
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "20%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "20%",
                  // color: "green",
                  // fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                TOTAL FOB INCL. COM. COST DZ
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalOrderQty} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalOrderQty} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {Number(totalAmount)?.toFixed(2)} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {Number(totalAmount)?.toFixed(2)} */}
              </Text>
              
                  <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>
                        {Number(totalAmount2)?.toFixed(2)}
                      </Text>
                  </View>
             
            </View>
            
          </View>

          {/* third Array */}
          <View>
            {tableBodyDataRefine3 &&
              tableBodyDataRefine3.length &&
              tableBodyDataRefine3.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  break={index > 8}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.group_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.costing_item}
                  </Text>
                 
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {item?.description} */}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {item?.consumption} */}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      borderRight: "1px solid black",
                      width: "12%",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.item_per).toFixed(2)} %
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {Number(item?.rate).toFixed(2)} */}
                  </Text>
                  <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>
                        {((Number(item?.item_per) * Number(totalAmount2)) / 100).toFixed(2)}
                      </Text>
                  </View>
                  
                </View>
              ))}
              <View
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
                borderTop: 0,
              }}
              wrap={false}
            >
              
             
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "20%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "left",
                }}
              >
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "20%",
                  // color: "green",
                  // fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                TOTAL GROSS FOB DZ
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalOrderQty} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalOrderQty} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {Number(totalAmount)?.toFixed(2)} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {Number(totalAmount)?.toFixed(2)} */}
              </Text>
              <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>
                        {Number(totalAmount3)?.toFixed(2)}
                      </Text>
                  </View>
             
            </View>
              <View
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
                borderTop: 0,
              }}
              wrap={false}
            >
              
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "20%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "left",
                }}
              >
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "20%",
                  // color: "green",
                  // fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                FOB PER PC
              </Text>
              
                          
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalOrderQty} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalOrderQty} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {Number(totalAmount)?.toFixed(2)} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {Number(totalAmount)?.toFixed(2)} */}
              </Text>
              <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>
                        {Number(totalAmount4)?.toFixed(2)}
                      </Text>
                  </View>
             
            </View>
           
          </View>
          {/* Fourth Array */}
          <View>
            {tableBodyDataRefine4 &&
              tableBodyDataRefine4.length &&
              tableBodyDataRefine4.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  break={index > 8}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.group_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "20%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.costing_item}
                  </Text>
                 
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {item?.description} */}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {item?.consumption} */}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      borderRight: "1px solid black",
                      width: "12%",
                      textAlign: "center",
                    }}
                  >
                    {/* {item?.uom} */}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {Number(item?.rate).toFixed(2)} */}
                  </Text>
                  <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>
                        {Number(item?.amount)?.toFixed(2)}
                      </Text>
                  </View>
                  
                </View>
              ))}
              <View
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
                borderTop: 0,
              }}
              wrap={false}
            >
             
             
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  height: "auto",
                  width: "20%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "left",
                }}
              >
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "8px",
                  height: "auto",
                  width: "20%",
                  // color: "green",
                  // fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                CNF PER PC
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalOrderQty} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {totalOrderQty} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {Number(totalAmount)?.toFixed(2)} */}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "12%",
                  color: "green",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {/* {Number(totalAmount)?.toFixed(2)} */}
              </Text>
              <View style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "12%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }} >
                      <Text>$</Text>
                      <Text>
                        {Number(totalAmount5)?.toFixed(2)}
                      </Text>
                  </View>
            </View>
          </View>
        </View>

      
        {/* Signature part */}
        <View
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Arial",

  fonts: [
    {
      src: "/asserts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/asserts/ARIALN.TTF",
    },
    {
      src: "/asserts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Arial",
    minWidth: "110vw",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
