import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import backgroundImg from "../../assets/background1.png";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../redux/actions/auth/authActions";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://clonestudiobd.com/">
        Clone Studio
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginGrid: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
  },
  paper: {
    margin: theme.spacing(1, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(2),
    width: theme.spacing(20),
    height: theme.spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "40%",
    marginTop: -10,
    marginLeft: -12,
  },
}));

const SignIn = ({ location, history }) => {
  const classes = useStyles();

  // const [user_id, setUser_id] = useState("");
  // const [password, setPassword] = useState("");

  const [credentials, setCredentials] = useState({
    user_id: "",
    password: "",
  });

  const dispatch = useDispatch();

  const [isLoading, setLoading] = React.useState(false);

  const [isError, setSetError] = React.useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const loginInfo = useSelector((state) => state?.loginInfoList?.loginInfo);

  const { loading, error } = useSelector((state) => state?.loginInfoList);

  const redirect = location.search ? location.search.split("=")[1] : "/app";

  useEffect(() => {
    if (loginInfo) {
      history.push(redirect);
      console.log("redirect", redirect);
    } else if (error) {
      setLoading(false);
    }
  }, [history, loginInfo, error, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (credentials.user_id !== "" && credentials.password !== "") {
      dispatch(loginAction(credentials));
      setLoading(true);
      setSetError(false);
    } else if (credentials.user_id === "" || credentials.password === "") {
      setSetError(true);
    }
  };

  console.log("loginInfo", loginInfo);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={6} md={6} />
      <Grid item xs={false} sm={1} md={1} />
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        component={Paper}
        elevation={6}
        square
        className={classes.loginGrid}
      >
        <div className={classes.paper}>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/logo-dark.svg`}
            style={{ height: 100, width: 250 }}
          />

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              required
              fullWidth
              id="email"
              label="User Name"
              name="user_id"
              autoComplete="email"
              error={isError && credentials.user_id === "" ? true : false}
              defaultValue={credentials.user_id}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              error={isError && credentials.password === "" ? true : false}
              defaultValue={credentials.password}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid container>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox size="small" value="remember" color="primary" />
                  }
                  label={<p style={{ fontSize: 14, margin: 0 }}>Remember me</p>}
                />
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
            </Grid>
            <div style={{ position: "relative" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitHandler}
              >
                {loading && <CircularProgress size={25} color="inherit" />}
                {!loading && "Sign In"}
              </Button>
              {/* {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )} */}
            </div>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
      <Grid item xs={false} sm={1} md={1} />
    </Grid>
  );
};

export default SignIn;
