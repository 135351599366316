import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COSTING_ITEM_GROUPS,
  BEGIN_EDIT_COSTING_ITEM_GROUPS,
  BEGIN_COSTING_ITEM_GROUPS_DOWNLOAD,
  RETRIEVE_COSTING_ITEM_GROUPS_BULK_DELETE,
  RETRIEVE_COSTING_ITEM_GROUPS_DELETE,
} from "../../constants/modules/costingItemGroups";

import {
  addCostingItemGroupErrorAction,
  addCostingItemGroupOkAction,
  bulkDeleteCostingItemGroupErrorAction,
  bulkDeleteCostingItemGroupOkAction,
  deleteCostingItemGroupErrorAction,
  deleteCostingItemGroupOkAction,
  downloadCostingItemGroupsErrorAction,
  downloadCostingItemGroupsOkAction,
  editCostingItemGroupErrorAction,
  editCostingItemGroupOkAction,
} from "../../actions/modules/costingItemGroupActions";

import {
  addCostingItemGroupDB,
  bulkDeleteCostingItemGroupDB,
  deleteCostingItemGroupDB,
  editCostingItemGroupDB,
  retrieveCostingItemGroupsData,
} from "../../../config/apiCalls/costingItemGroups";

// Retrieve products
// worker saga
export function* retrieveCostingItemGroups() {
  try {
    const response = yield call(retrieveCostingItemGroupsData);

    if (response.status === 200) {
      yield put(downloadCostingItemGroupsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadCostingItemGroupsErrorAction());
  }
}

// Create new product
// worker saga
export function* addCostingItemGroup(action) {
  const costing_item_group = action.costing_item_group;

  try {
    const response = yield call(addCostingItemGroupDB, costing_item_group);

    if (response.status === 201) {
      yield delay(500);

      yield put(addCostingItemGroupOkAction(costing_item_group));
      toast.success("costing_item_group created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addCostingItemGroupErrorAction(true));
    toast.error("CostingItemGroup created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteCostingItemGroup(action) {
  const id = action.payload;
  try {
    yield call(deleteCostingItemGroupDB, id);
    yield deleteCostingItemGroupOkAction();
    toast.success("CostingItemGroup deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteCostingItemGroupErrorAction();
  }
}

// Edit product
// worker saga
export function* editCostingItemGroup(action) {
  const costing_item_group = action.costing_item_group;

  try {
    yield call(editCostingItemGroupDB, costing_item_group);
    yield editCostingItemGroupOkAction(costing_item_group);
    // Alert
    toast.success("CostingItemGroup has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editCostingItemGroupErrorAction();
  }
}

// BULK SAGAS
export function* bulkCostingItemGroupDelete(action) {
  const costing_item_group = action.costing_item_group;

  try {
    const response = yield call(
      bulkDeleteCostingItemGroupDB,
      costing_item_group
    );
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteCostingItemGroupOkAction(costing_item_group));
      toast.success("Bulk CostingItemGroup deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteCostingItemGroupErrorAction(true));
    toast.error("Bulk CostingItemGroup deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchCostingItemGroupsAsync() {
  yield takeLatest(
    BEGIN_COSTING_ITEM_GROUPS_DOWNLOAD,
    retrieveCostingItemGroups
  );
  yield takeLatest(ADD_COSTING_ITEM_GROUPS, addCostingItemGroup);
  yield takeLatest(RETRIEVE_COSTING_ITEM_GROUPS_DELETE, deleteCostingItemGroup);
  yield takeLatest(BEGIN_EDIT_COSTING_ITEM_GROUPS, editCostingItemGroup);
  yield takeLatest(
    RETRIEVE_COSTING_ITEM_GROUPS_BULK_DELETE,
    bulkCostingItemGroupDelete
  );
}
