import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import MyDocument from "./MyDocument";

const Invoice = () => {

  React.useEffect(() => {
    document.title = "Export Summary SC";
  }, []);

  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

    const export_data = localStorage.getItem("export_summary_sc")
    ? JSON.parse(localStorage.getItem("export_summary_sc"))
    : null;
    
    console.log("export_data : ", export_data);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={export_data && export_data.length ? export_data : null}
        getInfo={getInfo}
      />
    </PDFViewer>
  );
};

export default Invoice;
