import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*              TNA ACTIONS API CALLS              */
/*-------------------------------------------------*/
export async function bulkDeleteTnaTemplateActionDB(ids) {
  return await axiosClient.post(`/tna_template_actions/bulk_delete`, ids);
}

export async function retrieveTnaTemplateActionsData() {
  return await axiosClient.get("/tna_template_actions");
}

export async function addTnaTemplateActionDB(value) {
  return await axiosClient.post("/tna_template_actions", value);
}

export async function deleteTnaTemplateActionDB(id) {
  return await axiosClient.delete(`/tna_template_actions/${id}`);
}

export async function editTnaTemplateActionDB(value) {
  return await axiosClient.put(`/tna_template_actions/${value.id}`, value);
}
