import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { Suspense, lazy, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Header, NotificationCenter, Sidebar, Workspace } from "../components";
import { useAppState } from "../components/AppProvider/AppProvider";
import axiosClient from "../config/axiosClient";
import useMountEffect from "../mountEffect";
import { downloadModulesAction } from "../redux/actions/menuActions";
import routes from "../routes";
import { MobileBreakpoint } from "../styleVariables";

//Components
const Users = lazy(() => import("../pages/modules/AdminPanel/Users"));
const Banks = lazy(() => import("../pages/modules/Settings/Banks"));
const Suppliers = lazy(() => import("../pages/modules/Settings/Suppliers"));
const Buyers = lazy(() => import("../pages/modules/Settings/Buyers"));
const Colors = lazy(() => import("../pages/modules/Settings/Colors"));
const Overseas_Offices = lazy(() =>
  import("../pages/modules/Settings/Overseas_Offices")
);
const Seasons = lazy(() => import("../pages/modules/Settings/Seasons"));
const Products = lazy(() => import("../pages/modules/Settings/Products"));
const ProductTypes = lazy(() =>
  import("../pages/modules/Settings/ProductTypes")
);
const ExFactories = lazy(() => import("../pages/modules/Settings/ExFactories"));
const FabricTypes = lazy(() => import("../pages/modules/Settings/FabricTypes"));
const Fabrics = lazy(() => import("../pages/modules/Settings/Fabrics"));
const Couriers = lazy(() => import("../pages/modules/Settings/Couriers"));
const Companies = lazy(() => import("../pages/modules/Settings/Companies"));
const Ports = lazy(() => import("../pages/modules/Settings/Ports"));
const BankAccounts = lazy(() =>
  import("../pages/modules/Settings/BankAccounts")
);
const BuyerTerms = lazy(() => import("../pages/modules/Settings/BuyerTerms"));
const UserAccessControl = lazy(() =>
  import("../pages/modules/AdminPanel/UserAccessControl")
);
const AppUserRole = lazy(() =>
  import("../pages/modules/AdminPanel/AppUserRole")
);
const FobTypes = lazy(() => import("../pages/modules/Settings/FobTypes"));
const Countries = lazy(() => import("../pages/modules/Settings/Countries"));
const Currencies = lazy(() => import("../pages/modules/Settings/Currencies"));
const Orders = lazy(() => import("../pages/modules/RegularWorks/Orders"));
const Teams = lazy(() => import("../pages/modules/Settings/Teams"));
const PaymentTerms = lazy(() =>
  import("../pages/modules/Settings/PaymentTerms")
);
const FactoryOrders = lazy(() =>
  import("../pages/modules/RegularWorks/FactoryOrders")
);
const TnaActions = lazy(() => import("../pages/modules/Settings/TnaActions"));
const TnaTemplates = lazy(() =>
  import("../pages/modules/RegularWorks/TnaTemplates")
);
const TnaPlan = lazy(() => import("../pages/modules/RegularWorks/TnaPlan"));
const Commissions = lazy(() =>
  import("../pages/modules/RegularWorks/Commissions")
);
const ExFactoryInfo = lazy(() =>
  import("../pages/modules/RegularWorks/ExFactoryInfo")
);
const SalesContract = lazy(() =>
  import("../pages/modules/RegularWorks/SalesContract")
);
const SalesContractAmd = lazy(() =>
  import("../pages/modules/RegularWorks/SalesContractAmend")
);
const LC = lazy(() => import("../pages/modules/RegularWorks/Lc"));
const LCTransfer = lazy(() =>
  import("../pages/modules/RegularWorks/LcTransfer")
);
const ApprovalPanel = lazy(() =>
  import("../pages/modules/AdminPanel/ApprovalPanel")
);
const ApprovalPanelMembers = lazy(() =>
  import("../pages/modules/AdminPanel/ApprovalPanelMembers")
);
const PendingExFactories = lazy(() =>
  import("../pages/modules/RegularWorks/PendingExFactories")
);
const Discounts = lazy(() => import("../pages/modules/RegularWorks/Discounts"));
const LCTSum = lazy(() =>
  import("../pages/modules/Documents/LCTransferSummary/index")
);

const CommissionReport = lazy(() =>
  import("../pages/modules/Documents/CommissionReport")
);

const FactoryInvoice = lazy(() =>
  import("../pages/modules/Documents/FactoryInvoice")
);

const RDLInvoice = lazy(() => import("../pages/modules/Documents/RDL Invoice"));
const DocSubmissions = lazy(() =>
  import("../pages/modules/Documents/DocSubmissions")
);
const TTAdvance = lazy(() => import("../pages/modules/RegularWorks/TTAdvance"));
const ApprovalFormSetup = lazy(() =>
  import("../pages/modules/AdminPanel/ApprovalFormSetup")
);
const ProceedRealization = lazy(() =>
  import("../pages/modules/RegularWorks/ProceedRealization")
);
const FactoryPayment = lazy(() =>
  import("../pages/modules/RegularWorks/FactoryPayment")
);

const CommissionInvoice = lazy(() =>
  import("../pages/modules/RegularWorks/CommissionInvoices")
);

const CommissionRealization = lazy(() =>
  import("../pages/modules/RegularWorks/CommissionRealization")
);

const TNAClubing = lazy(() =>
  import("../pages/modules/RegularWorks/TNAClubbing")
);

const LCAmend = lazy(() => import("../pages/modules/RegularWorks/LCAmend"));
const DashboardHome = lazy(() => import("../pages/modules/Dashboard"));

const OrderDoc = lazy(() => import("../pages/Reports/Orders"));

const FreightTerms = lazy(() =>
  import("../pages/modules/Settings/FreightTerms")
);

const ExFactorySales = lazy(() =>
  import("../pages/modules/Documents/ex-factory")
);

const HandoverSales = lazy(() => import("../pages/modules/Documents/handOver"));
const ETDSales = lazy(() => import("../pages/modules/Documents/etd"));
const ExportSummaryLC = lazy(() =>
  import("../pages/modules/Documents/ExportSummaryLCWise")
);
const ExportSummarySC = lazy(() =>
  import("../pages/modules/Documents/ExportSummarySCWise")
);

const TnaBasedCommercialReport = lazy(() =>
  import("../pages/modules/Documents/TNABasedCommercial")
);

const ExportReport = lazy(() => import("../pages/modules/Documents/Export"));

const UserLevels = lazy(() => import("../pages/modules/AdminPanel/UserLevels"));
const UserLevelPrivileges = lazy(() =>
  import("../pages/modules/AdminPanel/UserLevelPrivileges")
);

const AppMenus = lazy(() => import("../pages/modules/AdminPanel/AppMenus"));

const DebitNote = lazy(() => import("../pages/modules/RegularWorks/DebitNote"));

const LcScClosing = lazy(() =>
  import("../pages/modules/Documents/LcSc_Closing")
);

const Styles = lazy(() => import("../pages/modules/RegularWorks/Styles"));
const CostingItems = lazy(() =>
  import("../pages/modules/Settings/CostingItems")
);

const Costing = lazy(() => import("../pages/modules/RegularWorks/Costing"));
const CostingItemGroups = lazy(() =>
  import("../pages/modules/Settings/CostingItemGroups")
);

const ItemBookings = lazy(() =>
  import("../pages/modules/RegularWorks/ItemBooking")
);

const useStyles = makeStyles((theme) => ({
  panel: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      minHeight: "calc(100vh - 64px)",
      paddingTop: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      minHeight: "calc(100vh - 56px)",
      paddingTop: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 64px)",
    },
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 3,
  },
}));

const Dashboard = ({ history }) => {
  const classes = useStyles();
  const dispatch2 = useDispatch();

  const [state, dispatch] = useAppState();
  const [opened, setOpened] = useState(true);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [userDetails, setUserDetails] = useState("");

  const modules = useSelector((state) => state?.menuList?.modules);

  React.useEffect(() => {
    if (userDetails) {
      dispatch2(downloadModulesAction(userDetails));
    }
  }, [userDetails]);

  const mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);

  const resizeDispatch = () => {
    if (typeof Event === "function") {
      window.dispatchEvent(new Event("resize"));
    } else {
      const evt = window.document.createEvent("UIEvents");
      evt.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(evt);
    }
  };

  const handleDrawerToggle = () => {
    setOpened(!opened);
    resizeDispatch();
  };

  const handleNotificationToggle = () =>
    setNotificationsOpen(!notificationsOpen);

  const handleFullscreenToggle = () => {
    const element = document.querySelector("#root");
    const isFullscreen =
      document.webkitIsFullScreen || document.mozFullScreen || false;

    element.requestFullScreen =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      function () {
        return false;
      };
    document.cancelFullScreen =
      document.cancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      function () {
        return false;
      };
    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  const handleSpeedDialOpen = () => setOpenSpeedDial(true);

  const handleSpeedDialClose = () => setOpenSpeedDial(false);

  const scrollRef = useRef();

  const handleBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getRoutes = (
    <Switch>
      <Route path="/app/apps_users">
        <Users userDetails={userDetails} />
      </Route>
      <Route path="/app/banks">
        <Banks userDetails={userDetails} />
      </Route>
      <Route path="/app/suppliers">
        <Suppliers userDetails={userDetails} />
      </Route>
      <Route path="/app/buyers">
        <Buyers userDetails={userDetails} />
      </Route>
      <Route path="/app/colors">
        <Colors userDetails={userDetails} />
      </Route>
      <Route path="/app/overseas_offices">
        <Overseas_Offices userDetails={userDetails} />
      </Route>
      <Route path="/app/seasons">
        <Seasons userDetails={userDetails} />
      </Route>
      <Route path="/app/products">
        <Products userDetails={userDetails} />
      </Route>
      <Route path="/app/product_types">
        <ProductTypes userDetails={userDetails} />
      </Route>
      <Route path="/app/ex_factories">
        <ExFactories userDetails={userDetails} />
      </Route>
      <Route path="/app/fabric_types">
        <FabricTypes userDetails={userDetails} />
      </Route>
      <Route path="/app/fabrics">
        <Fabrics userDetails={userDetails} />
      </Route>

      <Route path="/app/couriers">
        <Couriers userDetails={userDetails} />
      </Route>

      <Route path="/app/companies">
        <Companies userDetails={userDetails} />
      </Route>

      <Route path="/app/ports">
        <Ports userDetails={userDetails} />
      </Route>

      <Route path="/app/bank_accounts">
        <BankAccounts userDetails={userDetails} />
      </Route>

      <Route path="/app/buyer_terms">
        <BuyerTerms userDetails={userDetails} />
      </Route>

      <Route path="/app/user_access_control">
        <UserAccessControl userDetails={userDetails} />
      </Route>

      <Route path="/app/user_roles">
        <AppUserRole userDetails={userDetails} />
      </Route>

      <Route path="/app/fob_types">
        <FobTypes userDetails={userDetails} />
      </Route>

      <Route path="/app/fob_types">
        <FobTypes userDetails={userDetails} />
      </Route>

      <Route path="/app/countries">
        <Countries userDetails={userDetails} />
      </Route>

      <Route path="/app/currencies">
        <Currencies userDetails={userDetails} />
      </Route>

      <Route path="/app/orders">
        <Orders userDetails={userDetails} handleBottom={handleBottom} />
      </Route>

      <Route path="/app/teams">
        <Teams userDetails={userDetails} />
      </Route>

      <Route path="/app/payment_terms">
        <PaymentTerms userDetails={userDetails} />
      </Route>

      <Route path="/app/factory_orders">
        <FactoryOrders userDetails={userDetails} />
      </Route>

      <Route path="/app/tna">
        <TnaActions userDetails={userDetails} />
      </Route>

      <Route path="/app/tna_templates">
        <TnaTemplates
          userDetails={userDetails}
          template_for={"Merchandising"}
        />
      </Route>

      <Route path="/app/tna_template_commercial">
        <TnaTemplates userDetails={userDetails} template_for={"Commercial"} />
      </Route>

      <Route path="/app/tna_plan">
        <TnaPlan userDetails={userDetails} />
      </Route>

      <Route path="/app/commissions">
        <Commissions userDetails={userDetails} />
      </Route>

      <Route path="/app/ex_factory_informations">
        <ExFactoryInfo userDetails={userDetails} />
      </Route>

      <Route path="/app/sales_contract">
        <SalesContract userDetails={userDetails} />
      </Route>

      <Route path="/app/sales_contract_amendment">
        <SalesContractAmd userDetails={userDetails} />
      </Route>

      <Route path="/app/lc_open">
        <LC userDetails={userDetails} />
      </Route>

      <Route path="/app/lc_transfer">
        <LCTransfer userDetails={userDetails} />
      </Route>

      <Route path="/app/approval_panels">
        <ApprovalPanel userDetails={userDetails} />
      </Route>

      <Route path="/app/approval_panel_members">
        <ApprovalPanelMembers userDetails={userDetails} />
      </Route>

      <Route path="/app/pending_ex_factories">
        <PendingExFactories userDetails={userDetails} />
      </Route>

      <Route path="/app/discounts">
        <Discounts userDetails={userDetails} />
      </Route>

      <Route path="/app/factory_invoices">
        <FactoryInvoice userDetails={userDetails} />
      </Route>

      <Route path="/app/rdl_invoices">
        <RDLInvoice userDetails={userDetails} />
      </Route>

      <Route path="/app/document_submissions">
        <DocSubmissions userDetails={userDetails} />
      </Route>

      <Route path="/app/tt_advance">
        <TTAdvance userDetails={userDetails} />
      </Route>

      <Route path="/app/approval_form_setup">
        <ApprovalFormSetup userDetails={userDetails} />
      </Route>

      <Route path="/app/proceed_realization">
        <ProceedRealization userDetails={userDetails} />
      </Route>

      <Route path="/app/factory_payment">
        <FactoryPayment userDetails={userDetails} />
      </Route>

      <Route path="/app/commission_invoice">
        <CommissionInvoice userDetails={userDetails} />
      </Route>

      <Route path="/app/commission_realization">
        <CommissionRealization userDetails={userDetails} />
      </Route>

      <Route path="/app/tna_invoice_clubbing">
        <TNAClubing userDetails={userDetails} />
      </Route>

      <Route path="/app/lc_amend">
        <LCAmend userDetails={userDetails} />
      </Route>

      <Route path="/app/dashboard">
        <DashboardHome userDetails={userDetails} />
      </Route>

      <Route path="/app/freight_terms">
        <FreightTerms userDetails={userDetails} />
      </Route>

      {/* DOC*/}

      <Route path="/app/lc_transfer_summary">
        <LCTSum userDetails={userDetails} />
      </Route>

      <Route path="/app/commission_report">
        <CommissionReport userDetails={userDetails} />
      </Route>

      <Route path="/app/ex_factory_wise_sales">
        <ExFactorySales userDetails={userDetails} />
      </Route>

      <Route path="/app/handover_date_wise_sales">
        <HandoverSales userDetails={userDetails} />
      </Route>

      <Route path="/app/etd_date_wise_sales">
        <ETDSales userDetails={userDetails} />
      </Route>

      <Route path="/app/export_report">
        <ExportReport userDetails={userDetails} />
      </Route>

      <Route path="/app/lc_export_summary">
        <ExportSummaryLC userDetails={userDetails} />
      </Route>

      <Route path="/app/sc_export_summary">
        <ExportSummarySC userDetails={userDetails} />
      </Route>

      <Route path="/app/tna_based_commercial_report">
        <TnaBasedCommercialReport userDetails={userDetails} />
      </Route>

      <Route path="/app/user_levels">
        <UserLevels userDetails={userDetails} />
      </Route>

      <Route path="/app/user_level-privileges">
        <UserLevelPrivileges userDetails={userDetails} />
      </Route>

      <Route path="/app/menus">
        <AppMenus userDetails={userDetails} />
      </Route>

      <Route path="/app/debit_note">
        <DebitNote userDetails={userDetails} />
      </Route>

      <Route path="/app/lc_sc_closing">
        <LcScClosing userDetails={userDetails} />
      </Route>

      <Route path="/app/styles">
        <Styles userDetails={userDetails} />
      </Route>

      <Route path="/app/costing_items">
        <CostingItems userDetails={userDetails} />
      </Route>

      <Route path="/app/costing_item_groups">
        <CostingItemGroups userDetails={userDetails} />
      </Route>

      <Route path="/app/costing">
        <Costing userDetails={userDetails} opened={opened} />
      </Route>

      <Route path="/app/item_booking">
        <ItemBookings userDetails={userDetails} />
      </Route>

      {/* <Redirect to="/404" /> */}
      <Redirect exact from="/app" to="/app/dashboard" />
    </Switch>
  );

  useMountEffect(() => {
    if (mediaMatcher.matches) setOpened(false);
    mediaMatcher.addListener((match) => {
      setTimeout(() => {
        if (match.matches) setOpened(false);
        else setOpened(true);
      }, 300);
    });

    const unlisten = history.listen(() => {
      if (mediaMatcher.matches) setOpened(false);
      document.querySelector("#root > div > main").scrollTop = 0;
    });

    return () => {
      unlisten();
      mediaMatcher.removeListener((match) => {
        setTimeout(() => {
          if (match.matches) setOpened(false);
          else setOpened(true);
        }, 300);
      });
    };
  });

  React.useEffect(() => {
    const getToken = localStorage.getItem("dm-userInfo")
      ? JSON.parse(localStorage.getItem("dm-userInfo"))
      : null;

    const token = "Bearer " + getToken?.token;

    console.log("session", getToken);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };

    axiosClient.get("/auth/login/user", config).then((response) => {
      setUserDetails(response?.data?.data);
    });

    // fetch(`https://suxes-api.clonestudiobd.com/api/auth/login/user`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: token,
    //   },
    // }).then((response) => {
    //   response.json().then((result) => {
    //     setUserDetails(result?.data);
    //   });
    // });
  }, []);

  const token = localStorage.getItem("dm-userInfo");

  if (!token) {
    return <Redirect to="/signin" />;
  } else {
    return (
      <>
        {userDetails && modules && modules.length ? (
          <>
            <Header
              logoAltText="Primer Admin Template"
              logo={`${process.env.PUBLIC_URL}/static/images/logo.svg`}
              toggleDrawer={handleDrawerToggle}
              toogleNotifications={handleNotificationToggle}
              toggleFullscreen={handleFullscreenToggle}
              userDetails={userDetails}
            />
            <div
              className={classNames(classes.panel, "theme-dark")}
              ref={scrollRef}
            >
              <Sidebar
                routes={routes.items}
                opened={opened}
                toggleDrawer={handleDrawerToggle}
                userDetails={userDetails}
                modules={modules}
              />
              <Workspace opened={opened} toggleDrawer={handleDrawerToggle}>
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "calc(100vh-70px)",
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </div>
                  }
                >
                  {getRoutes}
                </Suspense>
              </Workspace>
              <NotificationCenter
                notificationsOpen={notificationsOpen}
                toogleNotifications={handleNotificationToggle}
              />
            </div>
            {/* 
         <Hidden xsDown>
        <SpeedDial
          ariaLabel="Settings"
          className={classes.speedDial}
          icon={<SpeedDialIcon icon={<SettingsIcon />} />}
          onBlur={handleSpeedDialClose}
          onClose={handleSpeedDialClose}
          onFocus={handleSpeedDialOpen}
          onMouseEnter={handleSpeedDialOpen}
          onMouseLeave={handleSpeedDialClose}
          open={openSpeedDial}
        >
          <SpeedDialAction
            icon={<WbSunnyIcon />}
            tooltipTitle="Toggle light/dark theme"
            onClick={() => dispatch({ type: "type" })}
          />
          <SpeedDialAction
            icon={
              state.direction === "rtl" ? (
                <FormatTextdirectionLToRIcon />
              ) : (
                <FormatTextdirectionRToLIcon />
              )
            }
            tooltipTitle="Toggle LTR/RTL direction"
            onClick={() => dispatch({ type: "direction" })}
          />
        </SpeedDial>
      </Hidden>  */}
          </>
        ) : (
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </>
    );
  }
};

export default Dashboard;
