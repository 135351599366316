export const ADD_COSTING_ITEMS = "ADD_COSTING_ITEMS";
export const ADD_COSTING_ITEMS_OK = "ADD_COSTING_ITEMS_OK";
export const ADD_COSTING_ITEMS_ERROR = "ADD_COSTING_ITEMS_ERROR";

export const BEGIN_COSTING_ITEMS_DOWNLOAD = "BEGIN_COSTING_ITEMS_DOWNLOAD";
export const COSTING_ITEMS_DOWNLOAD_OK = "COSTING_ITEMS_DOWNLOAD_OK";
export const COSTING_ITEMS_DOWNLOAD_ERROR = "COSTING_ITEMS_DOWNLOAD_ERROR";

export const RETRIEVE_COSTING_ITEMS_DELETE = "RETRIEVE_COSTING_ITEMS_DELETE";
export const COSTING_ITEMS_DELETED_OK = "COSTING_ITEMS_DELETED_OK";
export const COSTING_ITEMS_DELETED_ERROR = "COSTING_ITEMS_DELETED_ERROR";

export const RETRIEVE_COSTING_ITEMS_EDIT = "RETRIEVE_COSTING_ITEMS_EDITED";
export const BEGIN_EDIT_COSTING_ITEMS = "BEGIN_EDIT_COSTING_ITEMS";
export const COSTING_ITEMS_EDITED_OK = "COSTING_ITEMS_EDITED_OK";
export const COSTING_ITEMS_EDITED_ERROR = "COSTING_ITEMS_EDITED_ERROR";

export const RETRIEVE_COSTING_ITEMS_BULK_DELETE =
  "RETRIEVE_COSTING_ITEMS_BULK_DELETE";
export const COSTING_ITEMS_BULK_DELETED_OK = "COSTING_ITEMS_BULK_DELETED_OK";
export const COSTING_ITEMS_BULK_DELETED_ERROR =
  "COSTING_ITEMS_BULK_DELETED_ERROR";
