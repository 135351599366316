import {
  ADD_COSTING_ITEMS,
  ADD_COSTING_ITEMS_ERROR,
  ADD_COSTING_ITEMS_OK,
  BEGIN_EDIT_COSTING_ITEMS,
  BEGIN_COSTING_ITEMS_DOWNLOAD,
  RETRIEVE_COSTING_ITEMS_BULK_DELETE,
  RETRIEVE_COSTING_ITEMS_DELETE,
  RETRIEVE_COSTING_ITEMS_EDIT,
  COSTING_ITEMS_BULK_DELETED_ERROR,
  COSTING_ITEMS_BULK_DELETED_OK,
  COSTING_ITEMS_DELETED_ERROR,
  COSTING_ITEMS_DELETED_OK,
  COSTING_ITEMS_DOWNLOAD_ERROR,
  COSTING_ITEMS_DOWNLOAD_OK,
  COSTING_ITEMS_EDITED_ERROR,
  COSTING_ITEMS_EDITED_OK,
} from "../../constants/modules/costingItemsConstants";

const initialState = {
  costing_items: [],
  error: false,
  loading: false,
  deleteStyle: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_COSTING_ITEMS_DOWNLOAD:
    case RETRIEVE_COSTING_ITEMS_BULK_DELETE:
    case ADD_COSTING_ITEMS:
    case BEGIN_EDIT_COSTING_ITEMS:
      return {
        ...state,
        loading: action.payload,
        costing_item: action.costing_item,
      };

    case ADD_COSTING_ITEMS_OK:
    case COSTING_ITEMS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_COSTING_ITEMS_ERROR:
    case COSTING_ITEMS_DOWNLOAD_ERROR:
    case COSTING_ITEMS_BULK_DELETED_ERROR:
    case COSTING_ITEMS_DELETED_ERROR:
    case COSTING_ITEMS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COSTING_ITEMS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        costing_items: action.payload,
      };

    case RETRIEVE_COSTING_ITEMS_DELETE:
      return {
        ...state,
        deleteCostingItem: action.payload,
      };

    case COSTING_ITEMS_DELETED_OK:
      return {
        ...state,
        costing_items: state.costing_items.data.filter(
          (costing_item) => costing_item.id !== state.deleteCostingItem
        ),
        deleteCostingItem: null,
      };

    case RETRIEVE_COSTING_ITEMS_EDIT:
      return {
        ...state,
        editCostingItem: action.payload,
      };

    case COSTING_ITEMS_EDITED_OK:
      return {
        ...state,
        editCostingItem: null,
        costing_items: state.costing_items.data.map((costing_item) =>
          costing_item.id === action.payload.id
            ? (costing_item = action.payload)
            : costing_item
        ),
      };

    default:
      return state;
  }
}
