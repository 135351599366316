import { PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import React from "react";
import MyDocument from "./MyDocument";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState([]);

  React.useEffect(() => {
    document.title = "Factory Order";
  }, []);

  const location = useLocation();
  let { id } = useParams();
  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  React.useEffect(() => {
    if (id) {
      fetch(`https://suxes-api.clonestudiobd.com/api/factory_orders/doc`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          p_id: id,
        }),
      }).then((response) => {
        response.json().then((result) => {
          setInvoiceData(result);
        });
      });
    }
  }, [id]);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={invoiceData && invoiceData.length ? invoiceData : null}
        getInfo={getInfo}
      />
    </PDFViewer>
  );
};

export default Invoice;
