import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import MyDocument from "./MyDocument";

import { useLocation, useParams } from "react-router-dom";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [invoiceData2, setInvoiceData2] = React.useState([]);
  const [state, setState] = React.useState({
    buyer: true,
    department: false,
    team: false,
    factory: false,
    product_type: false,
    quantity: true,
    commission_value: false,
    quantity_percentage: false,
    rdl_value: false,
    factory_value: false,
  });

  React.useEffect(() => {
    document.title = "Export";
  }, []);

  const location = useLocation();
  let { id } = useParams();
  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getState = localStorage.getItem("export_state")
    ? JSON.parse(localStorage.getItem("export_state"))
    : null;

  const getData = localStorage.getItem("export_report")
    ? JSON.parse(localStorage.getItem("export_report"))
    : null;

  const result =
    getData && getData.length
      ? getData?.map((item) => {
          let parameter = {
            factory_name: item.factory_name ? item.factory_name : "f",
            department: item.department ? item.department : "d",
            team_name: item.team_name ? item.team_name : "t",
            product_type: item.product_type ? item.product_type : "p",
          };
          return { ...item, ...parameter };
        })
      : [];

  console.log("export_report", getData);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={result ? result : null}
        state={getState}
        getInfo={getInfo}
      />
    </PDFViewer>
  );
};

export default Invoice;
