import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MyDocument from "./MyDocument";

const fakeData = [
  {
      "0": {
          "buyer_name": "WHISPERING SMITH ALL COSTING ",
          "style_name": "LJK - JULIA"
      }
  },
  {
      "0": {
          "id": 1,
          "costing_item_id": 1,
          "costing_item": "100% CTN SINGLEJERSEY",
          "description": "140",
          "uom": "KG",
          "consumption": "2.50",
          "rate": "4.40",
          "amount": "11.00",
          "group_name": "FABRIC",
          "costing_type": null,
          "item_per": null
      },
      "1": {
          "id": 2,
          "costing_item_id": 2,
          "costing_item": "100% CTN CHAMBRAY",
          "description": "110",
          "uom": "YD",
          "consumption": "2.00",
          "rate": "1.30",
          "amount": "2.60",
          "group_name": "FABRIC",
          "costing_type": null,
          "item_per": null
      },
      "2": {
          "id": 3,
          "costing_item_id": 3,
          "costing_item": "1X1 RIB",
          "description": "240",
          "uom": "KG",
          "consumption": "0.75",
          "rate": "6.00",
          "amount": "4.50",
          "group_name": "COLLAR+CUFF",
          "costing_type": null,
          "item_per": null
      },
      "3": {
          "id": 4,
          "costing_item_id": 4,
          "costing_item": "PRINT",
          "description": "",
          "uom": "",
          "consumption": "0.00",
          "rate": "0.00",
          "amount": "2.00",
          "group_name": "PRINT",
          "costing_type": null,
          "item_per": null
      },
      "4": {
          "id": 5,
          "costing_item_id": 5,
          "costing_item": "EMBROIDERY",
          "description": "",
          "uom": "",
          "consumption": "0.00",
          "rate": "0.00",
          "amount": "2.00",
          "group_name": "EMBROIDERY",
          "costing_type": null,
          "item_per": null
      },
      "5": {
          "id": 6,
          "costing_item_id": 6,
          "costing_item": "BASIC/ MEDIUM/ STRONG",
          "description": "",
          "uom": "",
          "consumption": "0.00",
          "rate": "0.00",
          "amount": "3.00",
          "group_name": "WASH",
          "costing_type": null,
          "item_per": null
      },
      "6": {
          "id": 7,
          "costing_item_id": 7,
          "costing_item": "TOTAL TRIMS",
          "description": "",
          "uom": "",
          "consumption": "0.00",
          "rate": "0.00",
          "amount": "3.00",
          "group_name": "TRIMS",
          "costing_type": null,
          "item_per": null
      },
      "7": {
          "id": 8,
          "costing_item_id": 8,
          "costing_item": "CM - DZ",
          "description": "",
          "uom": "",
          "consumption": "0.00",
          "rate": "0.00",
          "amount": "6.00",
          "group_name": "CM",
          "costing_type": null,
          "item_per": null
      }
  },
  {
      "0": {
          "id": 9,
          "costing_item_id": 10,
          "costing_item": "COMMERCIAL COST",
          "description": "",
          "uom": "",
          "consumption": "0.00",
          "rate": "0.00",
          "amount": "0.00",
          "group_name": "COST",
          "costing_type": null,
          "item_per": "3.00"
      }
  },
  {
      "0": {
          "id": 10,
          "costing_item_id": 12,
          "costing_item": "BUYING COMMISSION",
          "description": "",
          "uom": "",
          "consumption": "0.00",
          "rate": "0.00",
          "amount": "0.00",
          "group_name": "COMMISSION",
          "costing_type": null,
          "item_per": "5.00"
      }
  },
  {
      "0": {
          "id": 11,
          "costing_item_id": 15,
          "costing_item": "CNF SEA/ AIR",
          "description": "",
          "uom": "",
          "consumption": "0.00",
          "rate": "0.00",
          "amount": "0.15",
          "group_name": "CNF",
          "costing_type": null,
          "item_per": "0.00"
      }
  },
  {
      "fieldCount": 0,
      "affectedRows": 0,
      "insertId": 0,
      "info": "",
      "serverStatus": 34,
      "warningStatus": 0
  }
]




const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);

  React.useEffect(() => {
    document.title = "Costing Report";
  }, []);

  const location = useLocation();

  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getCostingInfo = localStorage.getItem("costing_info")
    ? JSON.parse(localStorage.getItem("costing_info"))
    : null;

  useEffect(() => {
    if (location) {
      const getData = localStorage.getItem("company-info")
        ? JSON.parse(localStorage.getItem("company-info"))
        : null;
      setCompanyData(getData);
    }
  }, [location]);

  useEffect(() => {
    fetch("https://step-up-api.clonestudiobd.com/api/companies")
      .then((res) => res.json())
      .then((data) => {
        const filterdData = data.data.filter(
          (item) => item.id === companyData?.id
        );
        setCompanyInfo(filterdData);
      });
  }, [companyData]);
  

  React.useEffect(() => {
    if (getCostingInfo) {
      fetch(`https://suxes-api.clonestudiobd.com/api/costings/preview`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          buyer_id: getCostingInfo?.buyer_id,
          style_id: getCostingInfo?.id,
        }),
      }).then((response) => {
        response.json().then((result) => {
          setInvoiceData(result);
        });
      });
    }
  }, []);

  console.log("invoiceData", invoiceData, "getCostingInfo", getCostingInfo);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
     {invoiceData ?
     (<MyDocument
        invoiceData={invoiceData ? invoiceData : []}
        companyInfo={companyInfo}
        getInfo={getInfo}
        getCostingInfo={getCostingInfo}
      />) : 
      (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )
      }
    </PDFViewer>
  );
};

export default Invoice;
